/* eslint-disable jsx-a11y/anchor-is-valid */
import { Section } from '../../../models/Section'
import KaTeXRenderer from '../../../pages/utils/KatexRenderer';
import "katex/dist/katex.min.css";
import clg from 'crossword-layout-generator';
import { systemSort } from '../../../utils/systemSort';
import DragDrop from './displayquestiontypes/DragAndDrop';
import SequenceHorizontal from './displayquestiontypes/SequenceHorizontal';
import SequenceVertical from './displayquestiontypes/SequenceVertical';
import AnnotatableImageView from './displayquestiontypes/ImageAnnotationView';
import MindMapQuestion from './displayquestiontypes/MindMapFill';
import { CrossWord } from './displayquestiontypes/Crossword';
export const PrintAnswers = (sections: Section[]) => {
  return (
    <div className='p-10 pb-0 text-dark row text-center' >
      <b className='mt-2'>Answers</b>
      {
        sections?.map((section: Section, index: number) => {
          return <div className='col-1 border border-dark'>
            <div><b>{index + 1}</b></div>
            <div>
              {
                section.type == 'mcq' && <b>{'(' + (parseInt(section.answer) + 1) + ')'}</b>
              }
              {
                section.type == 'multiple' &&  <>{'(' + section.answer?.map((answer: any) => {
                  return <b>{parseInt(answer) + 1},</b>
                }) + ')'}</>
              }
              {
                section.type == 'numerical' && <>{section.answer}</>
              }
            </div>
          </div>
        })
      }
      <div className='text-center mb-2 mt-6'><b>Solutions</b></div>
    </div>
  )
}