import { jsPDF } from "jspdf";
import { log } from "util";
function divideNumber(number: number, parts: number): number[] {
  const quotient = Math.floor(number / parts);
  const remainder = number % parts;
  const result = Array.from({ length: parts }, (_, index) =>
    index < remainder ? quotient + 1 : quotient
  );
  return result;
}

export function updateOMRInput(questionJSON: Record<string, number>): Record<string, number> {
  if (Object.keys(questionJSON).length > 4) return {};
  if (Object.keys(questionJSON).length > 2) {
    if (
      Object.keys(questionJSON).filter((k) => questionJSON[k] > 45).length > 0
    )
      return {};
    else return questionJSON;
  } else if (Object.keys(questionJSON).length == 2) {
    if (
      Object.keys(questionJSON).filter((k) => questionJSON[k] > 90).length > 0
    )
      return {};
    else {
      let parts1 = divideNumber(
        questionJSON[Object.keys(questionJSON)[0]],
        2
      );
      let updatedJSON: Record<string, number> = {};
      parts1.forEach(
        (part, index) =>
        (updatedJSON[
          Object.keys(questionJSON)[0] + '|' + index
        ] = part)
      );
      let parts2 = divideNumber(
        questionJSON[Object.keys(questionJSON)[1]],
        2
      );
      parts2.forEach(
        (part, index) =>
        (updatedJSON[
          Object.keys(questionJSON)[1] + '|' + index
        ] = part)
      );
      return updatedJSON;
    }
  } else if (Object.keys(questionJSON).length == 1) {
    if (
      Object.keys(questionJSON).filter((k) => questionJSON[k] > 180).length > 0
    )
      return {};
    else {
      let parts = divideNumber(
        questionJSON[Object.keys(questionJSON)[0]],
        4
      );
      let updatedJSON: Record<string, number> = {};
      parts.forEach(
        (part, index) =>
        (updatedJSON[
          Object.keys(questionJSON)[0] + '|' + index
        ] = part)
      );
      return updatedJSON;
    }
  }
  return {};
}
export const createOMRConfig = (sections: any, omrType: String) => {
  let OMRConfig: any = {}
  if (omrType) {
    OMRConfig = { 'Physics': 45, 'Chemistry': 45, 'Botany': 45, 'Zoology': 45 }
  } else {
    OMRConfig = { '': sections.length }
  }
  console.log(OMRConfig)
  return OMRConfig;
}
export function generateOMRSheet(
  heading1: string,
  heading2: string,
  logoURL: string,
  rollNoLength: number,
  questionJSON: Record<string, number>,
  beginNumberingWithSubject: boolean
): void {
  console.log("here")
  questionJSON = updateOMRInput(questionJSON);
  if (Object.keys(questionJSON).length === 0) return;

  // Create a new jsPDF instance with portrait orientation and A4 dimensions
  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'mm',
    format: 'a4',
  });

  // Set font size and type
  doc.setFontSize(8);
  doc.setFont('helvetica');

  // Draw black squares at the corners
  const cornerSize = 8;
  doc.setDrawColor(0); // Set the stroke color to black
  let markerUrl =
    'https://firebasestorage.googleapis.com/v0/b/olearno-4b1fb.appspot.com/o/omr_marker.jpg?alt=media&token=67f985dd-1865-482a-bd8f-dd747ac40c42';
  doc.addImage(markerUrl, 'JPEG', 10, 10, cornerSize, cornerSize);
  doc.addImage(
    markerUrl,
    'JPEG',
    10,
    doc.internal.pageSize.height - cornerSize - 10,
    cornerSize,
    cornerSize
  );
  doc.addImage(
    markerUrl,
    'JPEG',
    doc.internal.pageSize.width - cornerSize - 10,
    10,
    cornerSize,
    cornerSize
  );
  doc.addImage(
    markerUrl,
    'JPEG',
    doc.internal.pageSize.width - cornerSize - 10,
    doc.internal.pageSize.height - cornerSize - 10,
    cornerSize,
    cornerSize
  );
  const img = new Image();
  img.src = logoURL;
  img.onload = function () {
    const logoSize = 15;
    // doc.rect(15, 10, logoSize, logoSize);
    // Replace the placeholder link with the URL of your logo
    doc.addImage(
      img,
      logoURL.split(/[#?]/)[0].split('.').pop()?.trim()[0] == 'j' ? 'JPEG' : 'PNG',
      25,
      10,
      15,
      Math.round(img.naturalHeight * logoSize / img.naturalWidth)
    );
    // Add 2-line heading with increased font size
    const headingY = 10;
    const headingFontSize = 16; // Adjust the font size as needed
    doc.setFontSize(headingFontSize);
    doc.setFont('helvetica', 'bold');
    doc.text(heading1, 105, headingY, { align: 'center' });
    doc.text(heading2, 105, headingY + headingFontSize / 2, { align: 'center' });

    // Reset font size for the rest of the document
    doc.setFontSize(8);

    // Add space for name and roll number grid
    const nameY = 27;
    const rollNumberX = 100;
    doc.text(
      'Student Name: ___________________________________',
      15,
      nameY - 1
    );
    doc.text('Roll Number:', rollNumberX, nameY - 1);

    // Draw the roll number grid with empty circles
    const gridStartX = rollNumberX + 25;
    const gridStartY = nameY;
    const cellSize = 3;
    const cellPadding = 1;
    const gapSize = 3;

    for (let i = 0; i < rollNoLength + 1; i++) {
      for (let j = 0; j < 11; j++) {
        const circleX = gridStartX + i * (cellSize + cellPadding + gapSize);
        const circleY = gridStartY + j * (cellSize + cellPadding);
        if (j == 0) {
          if (i != 0) {
            doc.rect(
              circleX - (cellSize + cellPadding + gapSize) / 2,
              circleY - (cellSize + cellPadding),
              cellSize + cellPadding + gapSize,
              cellSize + cellPadding * 2
            );
          }
        } else {
          if (i == 0) {
            doc.text((j - 1) + '', circleX, circleY + 1, { align: 'center' });
          } else {
            doc.circle(circleX, circleY, 1.5, 'D');
          }
        }
      }
    }

    // Add headers for subjects with borders
    const subjects = Object.keys(questionJSON);
    const headerY = 75; // Adjusted position to avoid overlapping
    const columnWidth = 45;
    const questionCount = 45;
    const questionSpacing = 4;
    const showSubjectName =
      Object.keys(questionJSON)
        .map((subject) => subject.split('|')[0])
        .filter((value, index, array) => array.indexOf(value) === index).length > 1;
    let questionNumber = 0;
    for (let i = 0; i < subjects.length; i++) {
      const startX = 15 + i * columnWidth;

      // Add border around the entire subject
      doc.rect(startX, headerY, columnWidth, questionCount * questionSpacing + 20);
      if (showSubjectName)
        doc.text(subjects[i].split('|')[0], startX + 3, headerY + 5);

      // Add labels A, B, C, D at the top of each option column
      for (let k = 1; k < 5; k++) {
        const optionLabel = String.fromCharCode(64 + k); // A, B, C, D
        const optionLabelX = startX + k * 8;
        doc.text(optionLabel, optionLabelX + 3, headerY + 10);
      }

      // Add rows with question numbers and circles
      const startY = headerY + 12;
      for (let j = 1; j <= questionJSON[subjects[i]]; j++) {
        questionNumber = (beginNumberingWithSubject && subjects[i].split('|')[0] != subjects[i - 1]?.split('|')[0]) ? j : (questionNumber + 1);

        // Add question number
        doc.text(`${questionNumber}`, startX + 2, startY + j * questionSpacing);

        // Add circles for the current subject
        for (let k = 1; k < 5; k++) {
          const circleX = startX + 4 + k * 8;
          const circleY = startY + j * questionSpacing - 1;
          doc.circle(circleX, circleY, 1.5, 'D');
        }
      }
    }

    // Save the PDF
    doc.save(heading2 + '.pdf');
  }
  // Add logo

}
