import axios, { AxiosResponse } from 'axios';
import { FC, useState, useEffect, ChangeEvent } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import cv, { bool } from "@techstark/opencv-js";
import { DisplayLoader } from '../../utils/loader';
import { createOMRConfig, updateOMRInput } from '../../utils/generateOMR';
import { Modal } from 'react-bootstrap';
import { Stage, Layer, Image as KonvaImage, Circle, Line } from "react-konva";

type ApiParams = { id: string; presentState: string };
const width = 800
const height = 1000
const errorMargin = 5
const questionHeight = 655 / 44;
const optionWidth = 106 / 3;
const rollNoHeght = 134 / 9;
const rollNoWidth = 154 / 5;
const topLeftForQuestions = [[57, 282], [255, 282], [453, 282], [650, 282]]

const OMRCorrection: FC = () => {
    const { id, presentState } = useParams<ApiParams>();
    let [challenge, setChallenge] = useState<any>({});
    let [sections, setSections] = useState<any>({});
    let [batches, setBatches] = useState<any>([]);
    let [fileList, setFileList] = useState<any>([]);
    let [currentBatch, setCurrentBatch] = useState<any>({});
    let [template, setTemplate] = useState<any>({});
    let [marks, setMarks] = useState<any>([]);
    let [currentMark, setCurrentMark] = useState<any>(null);
    let [currentMarkIndex, setCurrentMarkIndex] = useState<number>(0);
    let [currentImage, setCurrentImage] = useState<any>(null);
    let conflict: any = {};
    let [conflictState, setConflictState] = useState<any>(null);
    let [showAnswerSheetModal, setShowAnswerSheetModal] = useState<boolean>(false);
    let [showEditAnswersModal, setShowEditAnswersModal] = useState<boolean>(false);
    let [showEditBordersModal, setShowEditBordersModal] = useState<boolean>(false);
    useEffect(() => {
        updateChallenge()
        updateBatches()
    }, []);
    const updateBatches = () => {
        DisplayLoader(true, '')
        axios.get(process.env.REACT_APP_API_URL + '/batch-list/').then((reply) => {
            if (reply?.data) {
                setBatches(reply.data)
            } else {
                DisplayLoader(false, '')
            }
        }, (error) => {
            DisplayLoader(false, '')
            //reject(error)//TODO
        })
    }
    const selectBatch = (batchId: string) => {
        DisplayLoader(true, '')
        axios.get(process.env.REACT_APP_API_URL + '/batch-users/' + batchId).then((reply) => {
            if (reply?.data) {
                let users: any = {}
                let usersIdMap: any = {}
                reply.data.forEach((u: any) => { users[u.rollNo] = u })
                reply.data.forEach((u: any) => { usersIdMap[u._id] = u })
                setCurrentBatch({ _id: batchId, users: users, usersIdMap: usersIdMap })
                updateMarks(batchId, usersIdMap)
            } else {

            }
            DisplayLoader(false, '')
        }, (error) => {
            DisplayLoader(false, '')
            //reject(error)//TODO
        })
    }
    const updateMarks = (batchId?: string, usersIdMap?: any) => {
        DisplayLoader(false, '')
        usersIdMap = usersIdMap || currentBatch.usersIdMap
        axios.get(process.env.REACT_APP_API_URL + '/marks/' + id + "/" + (batchId || currentBatch._id)).then((reply) => {
            if (reply?.data) {
                let newMarks = reply.data.map((mark: any) => {
                    const sumPositiveNegativePoints = (sections: { points: number }[]) => sections.reduce((acc, { points }) => (points > 0 ? acc.positive += points : acc.negative += points, acc), { positive: 0, negative: 0 });
                    let calculatedPoints = sumPositiveNegativePoints(Object.keys(mark.sections).map((section) => mark.sections[section]))
                    // rollNo =  usersIdMap[mark.userId].split('').map((r:string)=>[,r])
                    console.log(usersIdMap[mark.userId])
                    return {
                        name: mark.name, userId: mark.userId, total: mark.totalPoints, corners: mark.extraDetails?.omrData?.corners, points: mark.extraDetails?.omrData?.points, rollNoString: usersIdMap[mark.userId],
                        positive: calculatedPoints.positive, negative: calculatedPoints.negative, rollNo: usersIdMap[mark.userId].rollNo?.split('').map((r: string) => [, r])
                    }
                })
                console.log(newMarks)
                setMarks(newMarks)
                DisplayLoader(false, '')
            } else {
                DisplayLoader(false, '')
            }

        }, (error) => {
            DisplayLoader(false, '')
            //reject(error)//TODO
        })
    }
    const updateChallenge = () => {
        DisplayLoader(true, '')
        axios.get(process.env.REACT_APP_API_URL + '/get-challenge/' + id).then((reply) => {
            if (reply?.data) {
                setChallenge(reply.data.challenge);
                setSections(reply.data.sections);
                const omrConfig = createOMRConfig(reply.data.challenge.sections?.map((section: string) => reply.data.sections[section]), '')
                const omrInput = updateOMRInput(omrConfig)
                let template: any = {
                    "rollNo": {
                        'top_left': [519, 63]
                    },
                    "blocks": Object.values(omrInput).map((noOfQuestions: any, index: number) => {
                        return { type: 'MCQ', 'no_of_questions': noOfQuestions, 'top_left': topLeftForQuestions[index] }
                    })
                }
                template.answers = reply.data.challenge.sections?.map((section: string) => reply.data.sections[section].answer)
                setTemplate(template)
            } else {
                //TODO
            }
            DisplayLoader(false, '')
        }, (error) => {
            DisplayLoader(false, '')
            //reject(error)//TODO
        });
    }
    function detectBubbles(image: cv.Mat) {
        // Convert to grayscale
        let gray = new cv.Mat();
        cv.cvtColor(image, gray, cv.COLOR_RGBA2GRAY, 0);

        // Apply Gaussian blur
        let blurred = new cv.Mat();
        cv.GaussianBlur(gray, blurred, new cv.Size(5, 5), 0, 0, cv.BORDER_DEFAULT);
        // Detect circles using Hough Transform
        let circles = new cv.Mat();
        cv.HoughCircles(
            blurred, circles, cv.HOUGH_GRADIENT,
            1.2, 4, 40, 18, 6, 10
        );
        let filledBubbles: number[][] = [];
        let unfilledBubbles: number[][] = [];
        if (circles.rows > 0) {
            let circleData = new Float32Array(circles.data32F); // Convert to TypedArray for fast access

            for (let i = 0; i < circles.cols; i++) {
                let x = Math.round(circleData[i * 3]);
                let y = Math.round(circleData[i * 3 + 1]);
                let r = Math.round(circleData[i * 3 + 2]);

                // Slightly adjust radius but keep it controlled
                let adjustedRadius = Math.max(6, r * 1.1); // Avoid excessive radius increase

                // Define a bounding box (ROI) around the detected circle
                let x1 = Math.max(0, x - adjustedRadius);
                let y1 = Math.max(0, y - adjustedRadius);
                let x2 = Math.min(gray.cols, x + adjustedRadius);
                let y2 = Math.min(gray.rows, y + adjustedRadius);

                // Extract ROI for better localized processing
                let roi = gray.roi(new cv.Rect(x1, y1, x2 - x1, y2 - y1));

                // Apply adaptive thresholding instead of a simple mean intensity check
                let binaryROI = new cv.Mat();
                cv.adaptiveThreshold(roi, binaryROI, 255, cv.ADAPTIVE_THRESH_GAUSSIAN_C, cv.THRESH_BINARY_INV, 11, 2);

                // Count non-zero pixels (how much of the circle is dark)
                let darkPixelCount = cv.countNonZero(binaryROI);
                let totalPixelCount = roi.cols * roi.rows;
                let fillRatio = darkPixelCount / totalPixelCount;

                // Classify based on fill ratio instead of simple mean intensity
                if (fillRatio > 0.4) { // Adjust threshold based on testing (default ~50% fill)
                    filledBubbles.push([x, y]);
                } else {
                    unfilledBubbles.push([x, y]);
                }

                // Free memory
                roi.delete();
                binaryROI.delete()
            }
        }

        gray.delete();
        blurred.delete();
        circles.delete();

        return { filledBubbles, unfilledBubbles, image };
    }
    function resizeImage(image: cv.Mat, allBubbles: number[][], corners?: number[][]): { warpedImage: cv.Mat; corners: number[][]; matrix: cv.Mat } {
        let topLeft: number[] = [];
        let topRight: number[] = [];
        let bottomLeft: number[] = [];
        let bottomRight: number[] = [];

        if (!corners && allBubbles.length > 0) {
            topLeft = allBubbles.reduce((a, b) => (a[0] + a[1] < b[0] + b[1] ? a : b));
            bottomRight = allBubbles.reduce((a, b) => (a[0] + a[1] > b[0] + b[1] ? a : b));
            topRight = allBubbles.reduce((a, b) => (a[0] + (bottomRight[1] - a[1]) > b[0] + (bottomRight[1] - b[1]) ? a : b));
            bottomLeft = allBubbles.reduce((a, b) => ((bottomRight[0] - a[0]) + a[1] > (bottomRight[0] - b[0]) + b[1] ? a : b));
        }
        const finalCorners = corners || [topLeft, topRight, bottomRight, bottomLeft];

        const { warpedImage, matrix } = resizeImageWithCorners(image, finalCorners);

        return { warpedImage, corners: finalCorners, matrix };
    }
    function resizeImageWithCorners(image: cv.Mat, corners: number[][]) {
        const srcCorners = cv.matFromArray(4, 1, cv.CV_32FC2, [...corners[0], ...corners[1], ...corners[2], ...corners[3]]);
        const dstCorners = cv.matFromArray(4, 1, cv.CV_32FC2, [0, 0, width, 0, width, height, 0, height]);

        const matrix = cv.getPerspectiveTransform(srcCorners, dstCorners);
        const warpedImage = new cv.Mat();
        cv.warpPerspective(image, warpedImage, matrix, new cv.Size(width, height));
        return { warpedImage, matrix }
    }
    function transformPoints(points: number[][], matrix: cv.Mat): number[][] {
        return points.map(([x, y, r]) => {
            const srcPoint = cv.matFromArray(1, 1, cv.CV_32FC2, [x, y]);
            const dstPoint = new cv.Mat();
            cv.perspectiveTransform(srcPoint, dstPoint, matrix);

            const transformed = dstPoint.data32F;
            return [transformed[0], transformed[1], r];
        });
    }
    function detectAnswers(transformedBubbles: number[][], template: any, errorMargin: number, imageIndex: number, corners: number[][], filename: string): any {
        const answers: any[][] = Array(template.blocks.length).fill([]);
        const rollNo: any[][] = [Array(6).fill([])]
        transformedBubbles.forEach(point => {
            const pt = point.map(Math.round);
            if (pt) {
                let isAnswerPoint = false;
                template.blocks.forEach((block: any, index: number) => {
                    const bottom_right = [block.top_left[0] + 3 * optionWidth, block.top_left[1] + (block.no_of_questions - 1) * questionHeight]
                    if (pt[1] >= (block.top_left[1] - errorMargin) && pt[1] <= (bottom_right[1] + errorMargin) &&
                        pt[0] >= (block.top_left[0] - errorMargin) && pt[0] <= (bottom_right[0] + errorMargin)) {
                        const questionNo = Math.round((pt[1] - block.top_left[1]) / questionHeight);
                        if (answers[index].length === 0) {
                            answers[index] = Array(block.no_of_questions).fill([]);
                        }
                        const answer = Math.round((pt[0] - block.top_left[0]) / optionWidth);
                        answers[index][questionNo] = [point, answer];
                        isAnswerPoint = true
                        // cv.putText(image, answer.toString() + ":" + questionNo, new cv.Point(point[0], point[1]), cv.FONT_HERSHEY_SIMPLEX, 0.4, new cv.Scalar(0, 0, 255, 255), 1);
                    }
                });
                if (!isAnswerPoint) {
                    const bottom_right = [template["rollNo"]["top_left"][0] + rollNoWidth * 5, template["rollNo"]["top_left"][1] + rollNoHeght * 9]
                    if (pt[1] >= (template["rollNo"]["top_left"][1] - errorMargin) && pt[1] <= (bottom_right[1] + errorMargin) &&
                        pt[0] >= (template["rollNo"]["top_left"][0] - errorMargin) && pt[0] <= (bottom_right[0] + errorMargin)) {
                        const index = Math.round((pt[0] - template["rollNo"]["top_left"][0]) / rollNoWidth);
                        const value = Math.round((pt[1] - template["rollNo"]["top_left"][1]) / rollNoHeght);
                        rollNo[index] = [point, value];
                    }
                }
            }
        });

        const allAnswers = answers.flat()
        const rollNoString = rollNo.map((r: any) => r[1]).join('')
        if (!conflict[rollNoString]) conflict[rollNoString] = 1
        else conflict[rollNoString] += 1
        const mark = { total: 0, positive: 0, negative: 0, filename: filename, name: currentBatch?.users[rollNoString]?.name || 'Unknown', userId: currentBatch?.users[rollNoString]?._id, points: [Array(allAnswers.length).fill([])], imageIndex: imageIndex, corners: corners, rollNo: rollNo, rollNoString: rollNoString }
        allAnswers.forEach((answer: any, index) => {
            const sectionId = challenge.sections[index]
            if (answer && answer.length > 0) {
                if (sections[sectionId].answer == answer[1]) {
                    mark['points'][index] = [...answer, ...[true, challenge.markingScheme[sectionId][0], sectionId]]
                    mark['total'] += challenge.markingScheme[sectionId][0]
                    mark['positive'] += challenge.markingScheme[sectionId][0]
                } else {
                    mark['points'][index] = [...answer, ...[false, challenge.markingScheme[sectionId][1], sectionId]]
                    mark['total'] += challenge.markingScheme[sectionId][1]
                    mark['negative'] += challenge.markingScheme[sectionId][1]
                }
            } else {
                console.log("here1")
            }
        })
        console.log(allAnswers.length)
        return mark;
    }
    async function processImage(file: File, imageIndex: number, cornersIn?: [][]): Promise<void> {
        return new Promise((resolve) => {
            const reader = new FileReader();

            reader.onload = async (event) => {
                if (!event.target?.result) return;

                // Create an image element
                const img = new Image();
                img.src = event.target.result as string;

                img.onload = () => {
                    // Create a temporary canvas
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");
                    if (!ctx) return;

                    // Set canvas size to match image
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);

                    let src = cv.imread(img);
                    let scaleFactor = 0.5; // Adjust based on your needs
                    let resized = new cv.Mat();
                    cv.resize(src, resized, new cv.Size(src.cols * scaleFactor, src.rows * scaleFactor), 0, 0, cv.INTER_AREA);
                    let result = detectBubbles(src);

                    const { warpedImage, corners, matrix } = resizeImage(src, [...result.filledBubbles, ...result.unfilledBubbles], cornersIn)
                    warpedImage.delete()
                    const transformedBubbles = transformPoints(result.filledBubbles, matrix)
                    const mark = detectAnswers(transformedBubbles, template, errorMargin, imageIndex, corners, file.name)
                    src.delete();
                    resized.delete();
                    resolve(mark);
                };
            };

            reader.readAsDataURL(file);
        });
    }
    function processSingleImage(img: HTMLImageElement, imageIndex: number, cornersIn: [][]) {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        // Set canvas size to match image
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        let src = cv.imread(img);
        let scaleFactor = 0.5; // Adjust based on your needs
        let resized = new cv.Mat();
        cv.resize(src, resized, new cv.Size(src.cols * scaleFactor, src.rows * scaleFactor), 0, 0, cv.INTER_AREA);
        let result = detectBubbles(src);

        const { warpedImage, corners, matrix } = resizeImage(src, [...result.filledBubbles, ...result.unfilledBubbles], cornersIn)
        warpedImage.delete()
        const transformedBubbles = transformPoints(result.filledBubbles, matrix)
        const mark = detectAnswers(transformedBubbles, template, errorMargin, imageIndex, corners, currentMark.name)
        let newMarks = [...marks]
        newMarks[imageIndex] = mark
        setMarks(newMarks)
        setCurrentMark(mark)
        showAnswerImage(mark);
        setCurrentMarkIndex(imageIndex)
        setShowEditBordersModal(false);
        setShowAnswerSheetModal(true)
        resized.delete()
        src.delete();
    }
    const handleFiles = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        if (!files || files.length == 0) return;
        setFileList(files)

    };
    const scan = async () => {
        conflict = {}
        let allMarks = []
        for (let i = 0; i < fileList.length; i++) {
            const mark = await processImage(fileList[i], i);
            allMarks.push(mark)
            DisplayLoader(true, "Processing Image - " + (i + 1) + "/" + fileList.length)
        }
        DisplayLoader(false, '')
        setMarks(allMarks)
        setConflictState(conflict)
    }
    const showAnswerImage = (mark: any) => {
        const reader = new FileReader();
        if (fileList.length == 0) return
        reader.onload = async (event) => {
            if (!event.target?.result) return;

            // Create an image element
            const img = new Image();
            img.src = event.target.result as string;

            img.onload = () => {
                // Create a temporary canvas
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                if (!ctx) return;

                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);

                let image = cv.imread(img);
                const { warpedImage } = resizeImageWithCorners(image, mark.corners)
                for (let pt in mark.points) {
                    let point = mark.points[pt]
                    if (point?.[2] == true) {
                        cv.putText(warpedImage, (point[1] + 1).toString(), new cv.Point(point[0][0], point[0][1]), cv.FONT_HERSHEY_SIMPLEX, 0.4, new cv.Scalar(0, 255, 0, 255), 2);
                    }
                    if (point?.[2] == false) {
                        cv.putText(warpedImage, (point[1] + 1).toString(), new cv.Point(point[0][0], point[0][1]), cv.FONT_HERSHEY_SIMPLEX, 0.4, new cv.Scalar(255, 0, 0, 255), 2);
                    }
                }
                for (let pt in mark.rollNo) {
                    let point = mark.rollNo[pt]
                    cv.putText(warpedImage, point[1].toString(), new cv.Point(point[0][0], point[0][1]), cv.FONT_HERSHEY_SIMPLEX, 0.4, new cv.Scalar(0, 0, 255, 255), 2)
                    // cv.circle(warpedImage, new cv.Point(point[0], point[1]), 3, new cv.Scalar(0, 255, 0, 255), 3)
                }
                // cv.circle(warpedImage, new cv.Point(template['rollNo']['top_left'][0] + rollNoWidth * 5, template['rollNo']['top_left'][1] + rollNoHeght * 9), 3, new cv.Scalar(0, 255, 0, 255), 3)
                const answerCanvas = document.getElementById("canvas") as HTMLCanvasElement | null;
                if (answerCanvas) {
                    cv.imshow(answerCanvas, warpedImage);
                }
            };
        };

        reader.readAsDataURL(fileList[mark.imageIndex]);

    }
    const downloadCSV = () => {
        // Convert items into CSV format
        const header = ['Name', 'Total', 'Positive', 'Negative'];
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += header.join(",") + "\n";
        marks.forEach((mark: any) => {
            if (mark.name != 'Unknown') csvContent += [mark.name, mark.total, mark.positive, mark.negative].join(",") + "\n";
        });

        // Create a link element to trigger download
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", challenge.heading + ".csv");

        // Append the link to the DOM and simulate a click
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const uploadMarks = () => {
        let marksForUpload = marks.map((mark: any) => {
            if (mark.name == 'Unknown') return {}
            let sections: any = {}
            mark.points.forEach((point: any) => { sections[point[4]] = { answer: point[1], correct: point[2], points: point[3] } })
            return {
                userId: mark.userId, name: mark.name, totalPoints: mark.total, extraDetails: { omrData: { corners: mark.corners, points: mark.points } }, sections: sections
            }
        })
        DisplayLoader(true, '')
        axios.post(process.env.REACT_APP_API_URL + '/marks/', { marks: marksForUpload, challengeId: id, batchId: currentBatch._id }).then((reply) => {
            if (reply?.data) {
                updateMarks()
            } else {

            }
            DisplayLoader(false, '')
        }, (error) => {
            DisplayLoader(false, '')
            //reject(error)//TODO
        })
    }
    return (
        <div className='rounded border border-dark p-2 pb-0 shadow-lg bg-light shadow'>
            <h2 className='text-center mt-5 mb-5'>OMR Correction</h2>
            <div className='p-2'>
                <select className='form-control w-auto d-inline-block' onChange={(e: any) => { selectBatch(e.target.value) }}>
                    <option value={''}>--Select Batch--</option>
                    {batches?.map((batch: any) => {
                        return <option value={batch._id}>{batch.name}</option>
                    })}
                </select>
                <label className='form-label fw-bolder text-dark fs-6 d-block'>Choose files to upload</label>
                <input id='inputFiles' className='form-control w-auto d-inline-block' multiple type='file' onChange={handleFiles}></input>
                <button className='btn btn-primary ms-4 p-3' type='button' onClick={scan}>Scan</button>
            </div>
            <hr />
            <div className='mt-3'>
                <div>
                    <button className='btn btn-primary p-2 float-end' type='button' onClick={downloadCSV}>Download (.csv)</button>
                    <button className='btn btn-primary p-2 float-end me-4' type='button' onClick={uploadMarks}>Save</button>
                </div>
                <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th>Name</th><th>Total</th><th>Positive</th><th>Negative</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            marks.map((mark: any, index: number) => {
                                return <tr>
                                    <td>
                                        <div>
                                            {mark.name}
                                            {conflictState?.[mark.rollNoString] > 1 && <span className='ms-2'><i className='fa fa-exclamation-triangle text-warning'></i></span>}
                                            {mark.name == 'Unknown' && <span className='p-2 ms-2'><i className='fa fa-exclamation-triangle text-danger'></i></span>}
                                            <button type='button' className='btn btn-xs btn-default' onClick={() => {
                                                setCurrentMark(mark)
                                                setCurrentMarkIndex(index)
                                                if (mark.imageIndex) {
                                                    showAnswerImage(mark);
                                                    setShowAnswerSheetModal(true)
                                                } else {
                                                    setShowEditAnswersModal(true)
                                                }

                                            }}>
                                                <i className='fa fa-file'></i>
                                            </button>
                                        </div>
                                        <div>
                                            <span className='small'>{mark.filename}</span>
                                        </div>
                                    </td>
                                    <td>{mark.total}</td>
                                    <td>{mark.positive}</td>
                                    <td>{mark.negative}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
            <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
                show={showAnswerSheetModal} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowAnswerSheetModal(false) }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="showAnswerModalLabel">OMR</h5>
                        <button type="button" className="btn-close" onClick={() => { setShowAnswerSheetModal(false) }} aria-label="Close"></button>
                    </div>
                    <div className="modal-body pt-1">
                        <div className='mb-2'>
                            <button type='button' className='me-2 btn btn-primary p-2' onClick={() => { setShowAnswerSheetModal(false); setShowEditAnswersModal(true) }}>Edit Answers</button>
                            <button type='button' className='btn btn-primary p-2' onClick={() => {
                                if (fileList.length == 0) return
                                const img = new window.Image();
                                img.src = URL.createObjectURL(fileList[currentMark.imageIndex]);
                                img.onload = () => {
                                    setCurrentImage(img);
                                };
                                setShowAnswerSheetModal(false); setShowEditBordersModal(true)
                            }}>Edit Borders</button>
                        </div>
                        <canvas id="canvas"></canvas>
                    </div>
                </div>
            </Modal>
            <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
                show={showEditAnswersModal} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowEditAnswersModal(false) }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="showAnswerModalLabel">Edit Answers</h5>
                        <button type="button" className="btn-close" onClick={() => { setShowEditAnswersModal(false) }} aria-label="Close"></button>
                    </div>
                    <div className="modal-body pt-1">
                        <div>
                            {currentMark &&
                                <>
                                    <h3 className='m-2 ms-4'>Roll No</h3>
                                    {
                                        Array(10).fill(0).map((r: any, index: number) => {
                                            return <div className='mb-4'>
                                                <span style={{ width: '30px', display: 'inline-block' }}><b >{index}.</b></span>
                                                {Array(6).fill(0)?.map((rollNo: any, optionIndex: number) => {
                                                    return <span className='me-2'>
                                                        <input style={{ width: '20px', height: '20px' }} type='radio' checked={(currentMark.rollNo?.[optionIndex]?.[1] == index)} onChange={() => {
                                                            let newMarks = { ...currentMark }
                                                            let newConflict = { ...conflictState }
                                                            newConflict[newMarks.rollNoString] = Math.max(newConflict[newMarks.rollNoString] - 1, 0)
                                                            const oldIndex = newMarks.rollNo[optionIndex]?.[1] || 0
                                                            if (!newMarks.rollNo[optionIndex]) {
                                                                newMarks.rollNo[optionIndex] = []
                                                                newMarks.rollNo[optionIndex][0] = [template['rollNo']['top_left'][0] + optionIndex * rollNoWidth, template['rollNo']['top_left'][1]]
                                                            }
                                                            newMarks.rollNo[optionIndex][1] = index
                                                            newMarks.rollNo[optionIndex][0][1] = newMarks.rollNo[optionIndex][0][1] + (index - oldIndex) * rollNoHeght
                                                            newMarks.rollNoString = newMarks.rollNo.map((r: any) => r[1]).join('')
                                                            newMarks.name = currentBatch?.users[newMarks.rollNoString]?.name || 'Unknown'
                                                            newMarks.userId = currentBatch?.users[newMarks.rollNoString]?._id || 'Unknown'
                                                            if (!newConflict[newMarks.rollNoString]) newConflict[newMarks.rollNoString] = 0
                                                            newConflict[newMarks.rollNoString] += 1
                                                            setConflictState(newConflict)
                                                            setCurrentMark(newMarks)
                                                            let updateMarks = [...marks]
                                                            updateMarks[currentMarkIndex] = newMarks
                                                            setMarks(updateMarks)
                                                        }}></input>
                                                    </span>
                                                })}
                                            </div>
                                        })
                                    }
                                    <hr />
                                    <h3 className='m-2 ms-4'>Answers</h3>
                                    {challenge?.sections?.map((section: any, index: number) => {
                                        return <div className='mb-4'>
                                            <span style={{ width: '30px', display: 'inline-block' }}><b >{index + 1}.</b></span>
                                            {
                                                sections[section].type == 'mcq' && <>
                                                    {sections[section].options.map((option: any, optionIndex: number) => {
                                                        return <span className='me-2'>
                                                            <input style={{ width: '20px', height: '20px' }} type='radio' checked={(currentMark.points[index]?.[1] == optionIndex)} onChange={() => {
                                                                let newMarks = structuredClone(currentMark)
                                                                const marksDiff = challenge.markingScheme[section][0] - challenge.markingScheme[section][1]
                                                                if (!newMarks.points[index]) {
                                                                    newMarks.points[index] = []
                                                                    const findQuestionIndex: any = (template: { blocks: { no_of_questions: number }[] }, questionNumber: number): [number, number] =>
                                                                        template.blocks.reduce(([block, index, sum], b, i) => sum + b.no_of_questions >= questionNumber && block < 0 ? [i, questionNumber - sum - 1, sum + b.no_of_questions] : [block, index, sum + b.no_of_questions], [-1, -1, 0]).slice(0, 2) as [number, number];
                                                                    const questionIndex = findQuestionIndex(template, (index + 1))
                                                                    newMarks.points[index][0] = [template['blocks'][questionIndex[0]]['top_left'][0], template['blocks'][questionIndex[0]]['top_left'][1] + questionIndex[1] * questionHeight]
                                                                }
                                                                const oldIndex = newMarks.points[index][1]
                                                                const oldResult = newMarks.points[index][2]
                                                                newMarks.points[index][1] = optionIndex
                                                                newMarks.points[index][2] = optionIndex == sections[section].answer
                                                                if (oldIndex != optionIndex && newMarks.points[index][2] != oldResult) {
                                                                    if (currentMark.points[index]) {
                                                                        newMarks.points[index][3] = challenge.markingScheme[section][0]
                                                                        newMarks.total += (newMarks.points[index][2] ? marksDiff : -marksDiff)
                                                                        newMarks.positive += (newMarks.points[index][2] ? challenge.markingScheme[section][0] : -challenge.markingScheme[section][0])
                                                                        newMarks.negative += (newMarks.points[index][2] ? -challenge.markingScheme[section][1] : challenge.markingScheme[section][1])
                                                                    } else {
                                                                        newMarks.points[index][3] = challenge.markingScheme[section][1]
                                                                        newMarks.total += (newMarks.points[index][2] ? challenge.markingScheme[section][0] : challenge.markingScheme[section][1])
                                                                        newMarks.positive += (newMarks.points[index][2] ? challenge.markingScheme[section][0] : 0)
                                                                        newMarks.negative += (newMarks.points[index][2] ? 0 : challenge.markingScheme[section][1])
                                                                    }
                                                                }
                                                                newMarks.points[index][0][0] = newMarks.points[index][0][0] + (optionIndex - (oldIndex || 0)) * optionWidth
                                                                setCurrentMark(newMarks)
                                                                let updateMarks = [...marks]
                                                                updateMarks[currentMarkIndex] = newMarks
                                                                setMarks(updateMarks)
                                                            }}></input>
                                                        </span>
                                                    })}
                                                </>
                                            }
                                            {
                                                sections[section].type == 'numerical' && <>
                                                    <input type='text' className='input-sm form-control' style={{ width: '106px', display: "inline-block" }} value={sections[section].answer} onChange={((e) => { })}></input>
                                                </>
                                            }
                                            {currentMark.points[index]?.[2] == true && <span className='bg-success rounded p-2 ps-4 pe-4 ms-4' ><i className='fa fa-check text-white'></i></span>}
                                            {currentMark.points[index]?.[2] == false && <span className='bg-danger rounded p-2 ps-4 pe-4 ms-4' ><i className='fa fa-close text-white'></i></span>}
                                        </div>
                                    })}
                                </>
                            }
                            <button type='button' className='btn btn-primary' onClick={() => {
                                showAnswerImage(currentMark);
                                setShowEditAnswersModal(false);
                                setShowAnswerSheetModal(true)
                            }}>Save</button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
                show={showEditBordersModal} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowEditBordersModal(false) }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="setShowEditBordersModal">Edit Borders</h5>
                        <button type="button" className="btn-close" onClick={() => { setShowAnswerSheetModal(false) }} aria-label="Close"></button>
                    </div>
                    <div className="modal-body pt-1">
                        {currentImage && <><Stage width={currentImage.width} height={currentImage.height} className="border">
                            <Layer>
                                <KonvaImage image={currentImage} width={currentImage.width} height={currentImage.height} />

                                <Line
                                    points={currentMark.corners.flat().concat(currentMark.corners[0])} // Closing the shape
                                    stroke="blue"
                                    strokeWidth={2}
                                    closed
                                />
                                {currentMark.corners.map((point: number[], index: number) => (
                                    <Circle
                                        key={index}
                                        x={point[0]}
                                        y={point[1]}
                                        radius={6}
                                        fill="red"
                                        draggable
                                        onDragMove={(e) => {
                                            let newMark = { ...currentMark }
                                            newMark.corners[index] = [e.target.x(), e.target.y()]
                                            setCurrentMark(newMark)
                                        }}
                                    />
                                ))}
                            </Layer>
                        </Stage>
                            <button type='button' onClick={() => { processSingleImage(currentImage, currentMarkIndex, currentMark.corners) }} className='btn btn-primary float-end'>Process</button>
                        </>}
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export { OMRCorrection }
