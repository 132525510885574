import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { DisplayLoader } from '../../utils/loader';
import { boolean } from 'yup';

interface PageContentProps {
  questions: React.ReactNode[]; // Array of React nodes for questions
  maxPageHeight: number; // Maximum page height for layout
  maxExplainPageHeight: number;
  topContent?: React.ReactNode; // Content to display on the top of the first page
  explanations?: React.ReactNode[];
  answerContent?: React.ReactNode;
  headingText: string;
  watermarkText: string;
}
const WatermarkedDiv = (content: string) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(-30deg)',
        fontSize: '3rem',
        color: 'rgba(0, 0, 0, 0.2)',
        whiteSpace: 'nowrap',
        pointerEvents: 'none',
        zIndex: 1,
      }}
    >
      {content}
    </div>
  );
};
const PageContent: React.FC<PageContentProps> = ({ questions, maxPageHeight, maxExplainPageHeight, topContent, explanations, answerContent, headingText, watermarkText }) => {
  const [pages, setPages] = useState<React.ReactNode[][][]>([]);
  const [explanationPages, setExplanationPages] = useState<React.ReactNode[][][]>([]);
  const tempContainerRef = useRef<HTMLDivElement | null>(null);
  const initialLoad = () => {
    (document.getElementById('main-print') as HTMLElement).style.visibility = 'hidden'
    let allPages: React.ReactNode[][][] = [];
    let currentPage: React.ReactNode[][] = [];
    let currentColumn: React.ReactNode[] = [];
    questions.forEach((question, index) => {
      currentColumn.push(question);
    })
    currentPage.push(currentColumn);
    allPages.push(currentPage);
    setPages(allPages);
    allPages = [];
    currentPage = [];
    currentColumn = [];
    explanations?.forEach((explanation, index) => {
      currentColumn.push(explanation);
    })
    currentPage.push(currentColumn);
    allPages.push(currentPage);
    setExplanationPages(allPages);
  }
  // Helper function to wait for all images in a question to load
  const waitForImagesToLoad = (container: HTMLElement) => {
    const images = Array.from(container.querySelectorAll('img'));
    if (images.length === 0) return Promise.resolve(); // No images, resolve immediately

    return Promise.all(
      images.map((img) =>
        new Promise<void>((resolve) => {
          if (img.complete && img.naturalHeight !== 0) {
            // Resolve immediately if the image is already loaded
            resolve();
          } else {
            img.addEventListener('load', () => resolve(), { once: true });
            img.addEventListener('error', () => resolve(), { once: true });
          }
        })
      )
    );
  };

  const measureQuestions = async () => {
    const allPages: React.ReactNode[][][] = [];
    let currentPage: React.ReactNode[][] = [];
    let currentColumn: React.ReactNode[] = [];
    let currentHeight = 0;
    DisplayLoader(true, 'Generating. Please Wait...')
    await waitForImagesToLoad(document.body)
    let topContentHeight = (document.getElementById('questionTopContent') as HTMLElement).clientHeight
    let adjustedMaxHeightFirstPage = maxPageHeight - (topContentHeight * 2 + 10);
    Array.from(document.getElementsByClassName('question')).forEach((question, index) => {
      // Type assertion to ensure question is an HTML element
      const questionHeight = (question as HTMLElement).clientHeight;
      const currentPageHeight = allPages.length === 0 ? adjustedMaxHeightFirstPage : maxPageHeight;
      if (currentHeight + questionHeight > currentPageHeight / 2) {
        currentPage.push(currentColumn);
        currentColumn = [questions[index]];
        currentHeight = questionHeight;

        if (currentPage.length >= 2) {
          allPages.push(currentPage);
          currentPage = [];
        }
      } else {
        currentColumn.push(questions[index]);
        currentHeight += questionHeight;
      }
    });

    if (currentColumn.length > 0) currentPage.push(currentColumn);
    if (currentPage.length > 0) allPages.push(currentPage);
    setPages(allPages);
    const allExplanationPages: React.ReactNode[][][] = [];
    currentPage = [];
    currentColumn = [];
    currentHeight = 0;
    topContentHeight = (document.getElementById('answerTopContent') as HTMLElement).clientHeight
    adjustedMaxHeightFirstPage = maxExplainPageHeight - (topContentHeight * 2 - 40);
    let startAnswer = true;
    Array.from(document.getElementsByClassName('explanation')).forEach((question, index) => {
      // Type assertion to ensure question is an HTML element      
      const questionHeight = (question as HTMLElement).clientHeight;
      const currentPageHeight = startAnswer === true ? adjustedMaxHeightFirstPage : maxExplainPageHeight;
      if (currentHeight + questionHeight > currentPageHeight / 2) {
        currentPage.push(currentColumn);
        currentColumn = [explanations?.[index]];
        currentHeight = questionHeight;

        if (currentPage.length >= 2) {
          startAnswer = false
          allExplanationPages.push(currentPage);
          currentPage = [];
        }
      } else {
        currentColumn.push(explanations?.[index]);
        currentHeight += questionHeight;
      }
    });

    if (currentColumn.length > 0) currentPage.push(currentColumn);
    if (currentPage.length > 0) allExplanationPages.push(currentPage);
    DisplayLoader(false, '');
    (document.getElementById('main-print') as HTMLElement).style.visibility = 'visible'
    
    setExplanationPages(allExplanationPages)
  };

  // useEffect(() => {
  //   measureQuestions();
  // }, [questions, maxPageHeight]);
  useEffect(() => {
    initialLoad()
  }, [questions])
  useEffect(() => {
    let optionDivs = document.getElementsByClassName('options')
    let overflowingDivs = []
    for(let i = 0;i < optionDivs.length;i++) {
      if(optionDivs[i].scrollHeight > 25) {
        let qNo = optionDivs[i].className.split(' ').pop()
        qNo = qNo?.slice(1)
        overflowingDivs.push(qNo)
      }
    }
    overflowingDivs = overflowingDivs.filter((value, index, self) => self.indexOf(value) === index);
    overflowingDivs.forEach((d:any)=>{
      const divs = document.getElementsByClassName('options Q' + d)
      for(let i = 0;i < divs.length;i++) {
        divs[i].classList.remove('col-6')
        divs[i].classList.add('col-12')
      }
      
    })
    if (pages.length > 0 && (document.getElementById('main-print') as HTMLElement).style.visibility == 'hidden') {
      measureQuestions()
    }
  }, [pages])
  return (
    <>
    
    <div id='main-print' style={{ visibility: 'hidden' }}>
      <div id='question-print'>
      {pages.map((pageContent, pageIndex) => (
        <div className='pageQuestion' key={pageIndex} style={{
          position: 'relative',
          width: '200mm',
          height: '290mm',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          overflow: 'hidden',
        }}>
          {WatermarkedDiv(watermarkText)}
          {/* Render top content only on the first page */}
          {pageIndex === 0 && topContent && (
            <div id='questionTopContent' style={{
              boxSizing: 'border-box',
            }}>
              {topContent}
            </div>
          )}

          <div style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            border: '1px solid black',
            justifyContent: 'flex-start', // Left-align content
          }}>
            {pageContent.map((columnContent, colIndex) => (
              <div className={'columnQuestion'} key={colIndex} style={{
                flex: pageContent.length === 1 ? '0 0 50%' : 1, // Width for single column
                padding: '10px',
                boxSizing: 'border-box',
                overflow: 'hidden',
                pageBreakInside: 'avoid',
                borderRight: colIndex % 2 == 1 ? '' : '1px solid black',
                // borderRight: '1px solid black',
                // borderTop: '1px solid black',
                 // Right border for all columns
              }}>
                {columnContent.map((question, qIndex) => (
                  <div className='question' key={qIndex} style={{ pageBreakInside: 'avoid' }}>
                    {question}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ))}
      </div>
      <div id='explain-print'>
      {explanationPages.map((pageContent, pageIndex) => (
        <div className='pageExplanation' key={pageIndex} style={{
          position:'relative',
          width: '200mm',
          height: '290mm',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          overflow: 'hidden',
        }}>
          {WatermarkedDiv(watermarkText)}
          {/* Render top content only on the first page */}
          {pageIndex === 0 && answerContent && (
            <div id='answerTopContent' style={{
              boxSizing: 'border-box',
              border: '1px solid'
            }}>
              {answerContent}
            </div>
          )}

          <div style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'flex-start', // Left-align content
          }}>
            {pageContent.map((columnContent, colIndex) => (
              <div className={'columnExplanation'} key={colIndex} style={{
                flex: pageContent.length === 1 ? '0 0 50%' : 1, // Width for single column
                padding: '10px',
                boxSizing: 'border-box',
                overflow: 'hidden',
                pageBreakInside: 'avoid',
                borderLeft: colIndex % 2 == 1 ? '' : '1px solid black',
                borderTop: '1px solid black',
                borderBottom: '1px solid black',
                borderRight: '1px solid black', // Right border for all columns
              }}>
                {columnContent.map((question, qIndex) => (
                  <div className='explanation' key={qIndex} style={{ pageBreakInside: 'avoid' }}>
                    {question}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ))}
      </div>
    </div>
    </>
  );
};

export default PageContent;
