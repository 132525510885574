import axios, { AxiosResponse } from 'axios';
import { FC, useState, useEffect, ChangeEvent } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Formik, FormikValues, Field } from 'formik'
import { Section, sectionInit, ValidationSchema } from '../../models/Section'
import { Challenge, ChallengeValidationSchema, challengeFilterInit, challengeInit, challengeTypeToAddSectionNameMap, challengeTypeToQuestionTypeMap } from '../../models/Challenge';
import { ErrorDisplay } from '../../modules/components/common/ErrorDisplay';
import { DisplayLoader } from '../../utils/loader';
import { Present } from './Present';
import { ReportsList } from './ReportsList';
import { Modal } from 'react-bootstrap';

type ApiParams = { id: string; presentState: string };
const CreateModuleOffline: FC = () => {
  const { id, presentState } = useParams<ApiParams>();
  const navigate = useNavigate();
  let [headingEdit, setHeadingEdit] = useState<boolean>(false);
  let [editable, setEditable] = useState<boolean>(false);
  let [showMarkingModal, setShowMarkingModal] = useState<boolean>(false);
  let [sectionState, setSectionState] = useState<number>(0);//0-list,1-preview,2-edit
  let [challenge, setChallenge] = useState<any>({});
  let [sections, setSections] = useState<any>({});
  let [allSections, setAllSections] = useState<any>([]);
  let [currentSectionNo, setCurrentSectionNo] = useState<number>(0);
  let [initValues, setInitValues] = useState<any>(sectionInit);
  let [validationSchema, setValidationSchema] = useState<any>({});
  let [challengeValidationSchema, setChallengeValidationSchema] = useState<any>({});
  let [showCopyModal, setShowCopyModal] = useState<boolean>(false);
  let [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  useEffect(() => {
    updateChallenge()
  }, []);
  const updateChallenge = () => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/get-challenge/' + id).then((reply) => {
      setHeadingEdit(false);
      if (reply?.data) {
        setChallenge(reply.data.challenge);
        setSections(reply.data.sections);
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  const addSections = (values: any) => {
    DisplayLoader(true, '')
    let input = { noOfQuestions: values.noOfQuestions, type: values.type }
    axios.post(process.env.REACT_APP_API_URL + '/challenge-multi-add/' + id, input).then((reply) => {
      updateChallenge()
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  const updateAnswer = (answer: any, id: any) => {
    let newSections = {...sections}
    newSections[id].answer = answer
    setSections(newSections)
  }
  const updateMarkingScheme = (section: any, value: string, type: string) => {
    let newChallenge = { ...challenge }
    if (!newChallenge.markingScheme) newChallenge.markingScheme = {}
    if (!newChallenge.markingScheme[section]) newChallenge.markingScheme[section] = []
    if (type == 'positive') {
      newChallenge.markingScheme[section][0] = parseInt(value)
    } else {
      newChallenge.markingScheme[section][1] = parseInt(value)
    }
    setChallenge(newChallenge)
  }
  const saveAllSections = () => {
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/challenge-multi-save/' + id, {
      sections : Object.keys(sections).map((section:any)=>sections[section]),
      markingScheme : challenge.markingScheme
    }).then((reply) => {
      if (reply?.data) {
        updateChallenge();
      } else {
        DisplayLoader(false, '')
        //TODO
      }
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  const saveSection = (values: FormikValues) => {
    console.log('here')
    values.sectionId = values._id;
    values.challengeId = window.location.pathname.split("/")[2];
    values.positivePoints = values.positivePoints == null ? '5' : values.positivePoints
    values.negativePoints = values.negativePoints == null ? '0' : values.negativePoints
    setSectionState(0);
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/save-challenge/', values).then((reply) => {
      if (reply?.data) {
        updateChallenge();
      } else {
        DisplayLoader(false, '')
        //TODO
      }
    }, (error) => {
      //reject(error)//TODO
      DisplayLoader(false, '')
    });
  }
  const deleteSection = (sectionPosition: number) => {
    let values: any = {};
    values.challengeId = window.location.pathname.split("/")[2];
    values.sectionPosition = sectionPosition;
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/delete-section/', values).then((reply) => {
      if (reply?.data) {
        updateChallenge();
        setSectionState(0);
      } else {
        DisplayLoader(false, '')
        //TODO
      }
    }, (error) => {
      //reject(error)//TODO
      DisplayLoader(false, '')
    });
  }
  return (
    <div className='rounded border border-dark p-2 pb-0 shadow-lg bg-light shadow'>
      {challenge && challenge.heading &&
        <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
          validationSchema={validationSchema} initialValues={initValues} onSubmit={()=>{}}>
          {({ errors, touched, values, setFieldValue, setErrors }) => (
            <Form>
              <div>
                <div className='bg-light rounded p-2 col-12 d-inline-block'>
                  <div>
                    <label className='form-label fw-bolder text-dark fs-6'>Module Name</label>
                    <a href={'/create-sections/' + id} className='btn btn-info p-2 ms-3'>Add Sections</a>
                    <ReportsList id={id ? id : ''} />
                    {/* <Present id={id ? id : ''} challenge={challenge} sections={sections} presentStateIn={parseInt(presentState || '0')} /> */}
                    <button onClick={() => { navigate('/print-test', { state: { sections: challenge.sections?.map((section: string) => sections[section]), title: challenge?.heading } }) }} type='button' className='btn btn-info m-1 float-end p-2 pt-1 pb-1 shadow-lg'>
                      <i className="fa fa-tv"></i><small>Print</small>
                    </button>
                    <a href={'/omr-correction/' + id} className='btn btn-info m-1 float-end p-2 pt-1 pb-1 shadow-lg'>
                      <i className="fa fa-check-square"></i><small>OMR Correction</small>
                    </a>
                  </div>
                  <div className='mt-1'>
                    <div className='col-11 d-inline-block'>
                      <Field type='text' className='form-control form-control-lg mb-1'
                        name='heading' placeholder='Challenge Name' disabled={!headingEdit} />
                    </div>
                    <div className='col-1 d-inline-block'>
                      {!headingEdit && <button type='button' className='btn btn-sm btn-default' onClick={() => { setHeadingEdit(true) }}><i className="fa fa-pencil"></i></button>}
                      {headingEdit && <button className='btn btn-sm btn-dark' onClick={() => { setHeadingEdit(false); saveSection(values) }}><i className="fa fa-save"></i></button>}
                      <button type='button' className='btn btn-info p-2 pt-1 pb-1' onClick={() => { setShowCopyModal(true) }}><i className="fa fa-copy"></i></button>
                    </div>
                  </div>
                </div>
                {ErrorDisplay('heading', errors)}
                <hr className='m-2' />
                {
                  challenge.presentationMode == 'offline' &&
                  <>
                    <div>
                      {
                        challenge.sections.map((section: any, index: number) => {
                          return <div className='mb-4'>
                            <span style={{ width: '30px', display: 'inline-block' }}><b >{index + 1}.</b></span>
                            {
                              sections[section].type == 'mcq' && <>
                                {sections[section].options.map((option: any, optionIndex: number) => {
                                  return <span className='me-2'>
                                    <input style={{ width: '20px', height: '20px' }} type='radio' checked={(sections[section].answer == optionIndex)} onChange={()=>{updateAnswer(optionIndex, sections[section]._id)}}></input>
                                  </span>
                                })}
                              </>
                            }
                            {
                              sections[section].type == 'numerical' && <>
                                <input type='text' className='input-sm form-control' style={{ width: '106px', display: "inline-block" }} value={sections[section].answer} onChange={((e)=>{updateAnswer(e.target.value,  sections[section]._id)})}></input>
                              </>
                            }
                            <input className='input-sm form-control form-control-sm me-2 ms-4' style={{ width: "40px", textAlign: "center", display: "inline-block" }} type='text' value={challenge.markingScheme[sections[section]._id][0]} onChange={(e)=>{updateMarkingScheme(sections[section]._id, e.target.value, 'positive')}}></input>
                            <input className='input-sm form-control form-control-sm me-2' style={{ width: "40px", textAlign: "center", display: "inline-block" }} type='text' value={challenge.markingScheme[sections[section]._id][1]} onChange={(e)=>{updateMarkingScheme(sections[section]._id, e.target.value, 'negative')}}></input>
                            <button type='button' className='btn btn-danger btn-xs p-2 ps-4 pe-4 ms-4' onClick={()=>{deleteSection(index)}}>X</button>
                          </div>
                        })
                      }
                      {
                        challenge.sections.length > 0 && <div>
                          <button type='button' className='btn btn-primary' onClick={saveAllSections}>Save</button>
                        </div>
                      }
                    </div>
                    <hr className='m-2' />
                    <div className="d-flex align-items-center gap-2 p-3 m-3">
                      <Field type='text' className='form-control form-control-lg mb-1'
                        name='noOfQuestions' placeholder='No Of Questions' />
                      <Field as="select" name="type" className="form-select mb-1" onChange={(e: any) => { setFieldValue('type', e.target.value) }}>
                        <option value={''}><b>-Select-</b></option>
                        <option value={'mcq'}>MCQ</option>
                        <option value={'numerical'}>Numerical</option>
                      </Field>
                      <button type='button' onClick={() => { addSections(values) }} className='btn btn-dark ms-2'>Add</button>
                    </div>
                  </>
                }

              </div>
            </Form>
          )}
        </Formik>
      }

      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showDeleteModal} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowDeleteModal(false) }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="deleteModalLabel">Delete Section</h5>
            <button type="button" className="btn-close" onClick={() => { setShowDeleteModal(false) }} aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <h5>Are you sure that you want to delete this section?</h5>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => { setShowDeleteModal(false) }}>Close</button>
            <button type="button" className="btn btn-danger" onClick={() => { deleteSection(currentSectionNo); setShowDeleteModal(false) }}>Delete</button>
          </div>
        </div>
      </Modal>

    </div>
  )
}

export { CreateModuleOffline }
