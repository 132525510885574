/* eslint-disable jsx-a11y/anchor-is-valid */
import { Section } from '../../../models/Section'
import KaTeXRenderer from '../../../pages/utils/KatexRenderer';
import "katex/dist/katex.min.css";
import clg from 'crossword-layout-generator';
import { systemSort } from '../../../utils/systemSort';
import DragDrop from './displayquestiontypes/DragAndDrop';
import SequenceHorizontal from './displayquestiontypes/SequenceHorizontal';
import SequenceVertical from './displayquestiontypes/SequenceVertical';
import AnnotatableImageView from './displayquestiontypes/ImageAnnotationView';
import MindMapQuestion from './displayquestiontypes/MindMapFill';
import { CrossWord } from './displayquestiontypes/Crossword';
export const PrintExplanation = (section: Section, questionNo: number) => {
  const parseQuestion = (question: string, type: string) => {
    if (type === 'blanks' || type === 'blanks-any') {
      return question.replace(/#blank([0-9])#/g, '________');
    }
    if (type === 'click') {
      let q = question.replace(/<ca>|&lt;ca&gt;|<wa>|&lt;wa&gt;|<ba>|&lt;ba&gt;/g, '<button type="button" class="btn btn-info" onClick={function(e){blankClick(e)}}>').replace(/<\/ca>|&lt;\/ca&gt;|<\/wa>|&lt;\/wa&gt;|<\/ba>|&lt;\/ba&gt;/g, '</button>');
      console.log(q);
      return q;
    }
    return '';
  }
  const parseBlankAnswer = (text: string) => {
    const answerLength = text.match(/#blank([0-9])#/g)?.length || 0;
    let answer = text;
    for (let i = 0; i < answerLength; i++) {
      answer = answer.replaceAll("#blank" + (i + 1) + "#", '<button type="button" class="btn btn-info ms-2 me-2 mb-2" style="min-width: 100px" name="blanksQuestion' + (i + 1) + '" disabled>' + section.options[i] + '</button>')
    }
    return answer;
  }
  const parseCrossword = (options: string[][]) => {
    let result = clg.generateLayout(options.map((option: string[]) => { return { clue: option[1].replace(/<[^>]*>?/gm, ''), answer: option[0].replace(/<[^>]*>?/gm, '').toUpperCase() } })).result;
    let crosswordData: any = {
      'across': {},
      'down': {},
    }
    console.log(result)
    let crosswordIndexInfo: any = {}
    result.forEach((word: any, index: number) => {
      if (!crosswordIndexInfo[word.position]) {
        crosswordIndexInfo[word.position] = {};
      }
      if (word.orientation == 'across') {
        crosswordData['across'][word.position] = { clue: word.clue, answer: word.answer, row: word.starty - 1, col: word.startx - 1 }
        //crosswordData['across'][crosswordIndexInfo[word.position]] = { clue: word.clue, answer: options.filter((opt: string[]) => { return opt[0].replace(/<[^>]*>?/gm, '') == word.wordStr })[0][1].replace(/<[^>]*>?/gm, ''), row: word.xNum, col: word.yNum }
      } else {
        crosswordData['down'][word.position] = { clue: word.clue, answer: word.answer, row: word.starty - 1, col: word.startx - 1 }
      }
    })
    return crosswordData;
  }
  const isMatch = (index: any, category: any, answer: any, options: any) => {
    let ans = answer.filter((a: any) => { return a.split('-')[0] == index })[0]
    if (ans) {
      let ansRight = ans.split('-')[1]
      return category == options[ansRight][1]
    }
  };
  return (
    <div className='text-dark' >
      {
        section.explanation && section.explanation != '' && <div style={{ display: 'flex', alignItems: 'top' }}>
          <div style={{ width: '25px' }}>
            {questionNo ? questionNo + ')   ' : ''}
          </div>
          <div style={{ flex: '1' }}>
            <KaTeXRenderer>{section.explanation || ''}</KaTeXRenderer>
          </div>
        </div>
      }
    </div>
  )
}