import axios, { AxiosResponse } from 'axios';
import { FC, useState, useEffect, ChangeEvent } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Formik, FormikValues, Field } from 'formik'
import { EditSection } from '../../modules/components/display/EditSection'
import { Section, sectionInit, ValidationSchema } from '../../models/Section'
import { Challenge, ChallengeValidationSchema, challengeFilterInit, challengeInit, challengeTypeToAddSectionNameMap, challengeTypeToQuestionTypeMap } from '../../models/Challenge';
import { ViewSection } from '../../modules/components/display/ViewSection';
import { ErrorDisplay } from '../../modules/components/common/ErrorDisplay';
import { DisplayLoader } from '../../utils/loader';
import { Present } from './Present';
import { SevenLives } from '../../modules/components/display/displayquestiontypes/7lives';
import { check } from 'prettier';
import { Reports } from '../reports/Reports';
import { ReportsList } from './ReportsList';
import { Modal } from 'react-bootstrap';
import Select from 'react-select'
import { subTopicList, topicListForStream } from '../../models/LocalLists';

type ApiParams = { id: string; presentState: string };
const CreateModule: FC = () => {
  const { id, presentState } = useParams<ApiParams>();
  const navigate = useNavigate();
  let [headingEdit, setHeadingEdit] = useState<boolean>(false);
  let [editable, setEditable] = useState<boolean>(false);
  let [showMarkingModal, setShowMarkingModal] = useState<boolean>(false);
  let [sectionState, setSectionState] = useState<number>(0);//0-list,1-preview,2-edit
  let [challenge, setChallenge] = useState<any>({});
  let [sections, setSections] = useState<any>({});
  let [allSections, setAllSections] = useState<any>([]);
  let [search, setSearch] = useState<any>({ searchItems: [], skipCount: 0, selectedItems: [], more: false });
  let [subjectQuestionConfig, setSubjectQuestionConfig] = useState<any>([{ topics: [], noOfQuestions: 10, questionType: { value: 'mcq', label: 'MCQ' }, difficultyLevel: { value: 'any', label: 'Any' } }]);
  let [currentSectionNo, setCurrentSectionNo] = useState<number>(0);
  let [initValues, setInitValues] = useState<any>(sectionInit);
  let [challengeInitValues, setChallengeInitValues] = useState<Challenge>(challengeInit);
  let [validationSchema, setValidationSchema] = useState<any>({});
  let [replacementTopics, setReplacementTopics] = useState<any>([]);
  let [challengeValidationSchema, setChallengeValidationSchema] = useState<any>({});
  let [challengeFilter, setChallengeFilter] = useState<any>(challengeFilterInit);
  let [showCopyModal, setShowCopyModal] = useState<boolean>(false);
  let [showAddModal, setShowAddModal] = useState<boolean>(false);
  let [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  let [showSelectModal, setShowSelectModal] = useState<boolean>(false);
  let [showReplaceModal, setShowReplaceModal] = useState<boolean>(false);
  let [errors, setErrors] = useState<any>('');
  let [hasInsufficientQuestions, setHasInsufficientQuestions] = useState<boolean>(false);
  useEffect(() => {
    updateCurrentSection(challenge, sections)
  }, [currentSectionNo, sections])
  useEffect(() => {
    if (id) {
      updateChallenge();
      updateChallengeValidationSchema()
    } else {
      createChallenge();
    }
    let filterQuestionTypeElements = document.getElementsByName('fillterqtype');
    for (let i = 0; i < filterQuestionTypeElements.length; i++) {
      (filterQuestionTypeElements[i] as HTMLInputElement).checked = true;
    }
  }, []);
  const updateChallengeFilter = (type: string) => {
    let updatedChallengeFilter = { ...challengeFilter }
    updatedChallengeFilter[type] = !updatedChallengeFilter[type];
    console.log(challengeFilter)
    setChallengeFilter(updatedChallengeFilter)
  }
  const updateValidationSchema = (values: Section) => {
    setValidationSchema(ValidationSchema(values, false, (challenge && challenge.heading && challenge.heading !== '')));
  }
  const updateChallengeValidationSchema = () => {
    setChallengeValidationSchema(ChallengeValidationSchema());
  }
  const updateCurrentSection = (challenge: any, sections: any) => {
    if (challenge.sections && challenge.sections.length > 0 && challenge.sections[currentSectionNo]) {
      let data = sections[challenge.sections[currentSectionNo]] || {}
      data.heading = challenge.heading;
      setInitValues(data);
      updateValidationSchema(data);
    } else {
      let data = { heading: challenge.heading }
      setInitValues(data);
      updateValidationSchema(data);
    }
  }
  const updateChallenge = (sectionNo?: number) => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/get-challenge/' + id).then((reply) => {
      setHeadingEdit(false);
      if (reply?.data) {
        setChallenge(reply.data.challenge);
        setSections(reply.data.sections);
        setAllSections([...allSections, ...Object.keys(reply.data.sections)].filter((item, index, self) => self.indexOf(item) === index));
        setEditable(reply.data.editable)
        if (sectionNo) {
          setCurrentSectionNo(sectionNo)
        } else {
          updateCurrentSection(reply.data.challenge, reply.data.sections)
          if (reply.data.challenge.sections[currentSectionNo]) {

          } else {
            setCurrentSectionNo(0);
          }
        }
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  const createChallenge = () => {
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/save-challenge/').then((reply) => {
      if (reply?.data?.challengeId) {
        window.location.pathname = ((reply?.data?.presentationMode && (reply?.data?.presentationMode == 'offline')) ? '/create-test-offline/' : '/create-module/') + reply.data.challengeId;
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  const copyModule = () => {
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/copy-challenge/' + id).then((reply) => {
      console.log(reply.data)
      if (reply?.data?.challenge?._id) {
        window.location.pathname = '/create-module/' + reply.data.challenge._id;
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  const updateMarkingScheme = (section: any, value: string, type: string) => {
    let newChallenge = { ...challenge }
    if (!newChallenge.markingScheme) newChallenge.markingScheme = {}
    if (!newChallenge.markingScheme[section]) newChallenge.markingScheme[section] = []
    if (type == 'positive') {
      newChallenge.markingScheme[section][0] = parseInt(value)
    } else {
      newChallenge.markingScheme[section][1] = parseInt(value)
    }
    setChallenge(newChallenge)
  }
  const copyMarkingScheme = (index: number) => {
    const section = challenge.sections[index]
    if (!challenge.markingScheme || !challenge.markingScheme[section] || challenge.markingScheme[section].length != 2) return
    let newChallenge = { ...challenge }
    for (let i = (index + 1); i < challenge.sections.length; i++) {
      newChallenge.markingScheme[challenge.sections[i]] = [...challenge.markingScheme[section]]
    }
    setChallenge(newChallenge)
  }
  const saveMarkingScheme = () => {
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/marking-scheme/' + window.location.pathname.split("/")[2], { markingScheme: challenge.markingScheme, extraDetails: challenge.extraDetails }).then((reply) => {
      if (reply?.data) {
        updateChallenge();
      } else {
        DisplayLoader(false, '')
        //TODO
      }
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  const saveChallenge = (values: FormikValues) => {
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/challenge/' + window.location.pathname.split("/")[2], values).then((reply) => {
      if (reply?.data) {
        if (reply?.data?.challenge.presentationMode == 'offline') {
          window.location.pathname = '/create-test-offline/' + reply.data.challenge._id;
        } else {
          updateChallenge();
        }
      } else {
        DisplayLoader(false, '')
        //TODO
      }
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  const saveSection = (values: FormikValues) => {
    console.log('here')
    values.sectionId = values._id;
    values.challengeId = window.location.pathname.split("/")[2];
    values.positivePoints = values.positivePoints == null ? '5' : values.positivePoints
    values.negativePoints = values.negativePoints == null ? '0' : values.negativePoints
    setSectionState(0);
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/save-challenge/', values).then((reply) => {
      if (reply?.data) {
        updateChallenge();
      } else {
        DisplayLoader(false, '')
        //TODO
      }
    }, (error) => {
      //reject(error)//TODO
      DisplayLoader(false, '')
    });
  }
  const moveSection = (direction: string, index: number) => {
    let array = [...challenge.sections]
    if (direction === 'up' && index > 0) {
      [array[index], array[index - 1]] = [array[index - 1], array[index]];
    } else if (direction === 'down' && index < array.length - 1) {
      [array[index], array[index + 1]] = [array[index + 1], array[index]];
    }
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/challenge/' + window.location.pathname.split("/")[2], { sections: array }).then((reply) => {
      if (reply?.data) {
        updateChallenge();
      } else {
        DisplayLoader(false, '')
        //TODO
      }
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  const addSection = (sectionPosition: number) => {
    console.log(sectionPosition)
    let values: any = {};
    values.challengeId = window.location.pathname.split("/")[2];
    values.sectionPosition = sectionPosition;
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/save-challenge/', values).then((reply) => {
      if (reply?.data) {
        updateChallenge(sectionPosition);
        setSectionState(2);
      } else {
        DisplayLoader(false, '')
        //TODO
      }
    }, (error) => {
      //reject(error)//TODO
      DisplayLoader(false, '')
    });
  }
  const deleteSection = (sectionPosition: number) => {
    let values: any = {};
    values.challengeId = window.location.pathname.split("/")[2];
    values.sectionPosition = sectionPosition;
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/delete-section/', values).then((reply) => {
      if (reply?.data) {
        updateChallenge(sectionPosition);
        setSectionState(0);
      } else {
        DisplayLoader(false, '')
        //TODO
      }
    }, (error) => {
      //reject(error)//TODO
      DisplayLoader(false, '')
    });
  }
  const selectCustomModalByUrl = () => {
    if (window.location.href.indexOf('create-test') != -1) {
      setShowAddModal(true)
    }
    if (window.location.href.indexOf('create-module') != -1) {
      setShowSelectModal(true)
    }
  }
  const initializeSearch = () => {
    setSearch({ searchItems: [], skipCount: 0, selectedItems: [], more: false });
    (document.getElementById('searchText') as HTMLInputElement).value = '';
  }
  const replaceSection = (addToReplacementSections?: boolean) => {
    console.log(replacementTopics)
    let usedSections = [...allSections]
    if (addToReplacementSections) {
      usedSections = [...search?.searchItems?.map((s: Section) => s._id), ...allSections]
    }
    axios.post(process.env.REACT_APP_API_URL + '/replace-question/',
      { fineTags: replacementTopics, type: sections[challenge.sections[currentSectionNo]].type, targetTest: sections[challenge.sections[currentSectionNo]].fineTags.targetTest, usedSections: allSections })
      .then((reply) => {
        if (reply?.data?.questions) {
          setSearch({ searchItems: [...search?.searchItems, ...reply.data.questions], selectedItems: [] });
          DisplayLoader(false, '')
        } else {
          //TODO
        }
      }, (error) => {
        //reject(error)//TODO
        DisplayLoader(false, '')
      });
  }
  const searchQuestions = (skipCount: number) => {
    let searchText = (document.getElementById('searchText') as HTMLInputElement).value;
    if (searchText.length < 3) {
      (document.getElementById('searchWarning') as HTMLElement).style.display = 'block';
      (document.getElementById('addModalClose') as HTMLElement).className = 'btn-close mb-7'
      return
    }
    (document.getElementById('searchWarning') as HTMLElement).style.display = 'none';
    (document.getElementById('addModalClose') as HTMLElement).className = 'btn-close mb-4'
    let filterQuestionTypeElements = document.getElementsByName('fillterqtype');
    let questionTypes = [];
    for (let i = 0; i < filterQuestionTypeElements.length; i++) {
      if ((filterQuestionTypeElements[i] as HTMLInputElement).checked) {
        questionTypes.push((filterQuestionTypeElements[i] as HTMLInputElement).value);
      }
    }
    axios.post(process.env.REACT_APP_API_URL + '/search-section/', { queryString: searchText, skipCount: skipCount, questionTypes: questionTypes }).then((reply) => {
      if (reply?.data && Array.isArray(reply.data)) {
        if (skipCount === 0) {
          let searchItems = [...search.selectedItems, ...reply.data]
          searchItems = searchItems.filter((item, pos) => searchItems.indexOf(item) === pos)
          setSearch({ searchItems: searchItems, skipCount: skipCount, selectedItems: search.selectedItems, more: reply.data.length < 10 ? false : true });
          (document.getElementById('searchList') as HTMLInputElement).scrollTo(0, 0);
        } else {
          let searchItems = [...search.searchItems, ...search.searchItems, ...reply.data]
          searchItems = searchItems.filter((item, pos) => searchItems.indexOf(item) === pos)
          setSearch({ searchItems: searchItems, skipCount: skipCount, selectedItems: search.selectedItems, more: reply.data.length < 10 ? false : true });
        }
        //updateChallenge(sectionPosition);
      } else {

      }
    }, (error) => {
      //reject(error)//TODO
    });
  }
  const searchQuestionsByTag = (values?: FormikValues) => {
    setErrors('')
    setHasInsufficientQuestions(false)
    let usedSections = [...search?.searchItems?.map((s: Section) => s._id), ...allSections]
    let errorText = ''
    subjectQuestionConfig.forEach((element: any) => {
      if (element.topics.length == 0 || element.noOfQuestions == 0 || element.questionType.length == 0) {
        errorText = 'There is an error in the details of the test';
      }
    })
    if (errorText) {
      setErrors(errorText)
      return
    }
    DisplayLoader(true, 'Finding Questions')
    axios.post(process.env.REACT_APP_API_URL + '/question-paper-generator/',
      { config: { ...{ testType: 'subject', difficultyExcluded: true, source: 'active' } }, usedSections: usedSections, subjectQuestionConfig: subjectQuestionConfig })
      .then((reply) => {
        if (reply?.data?.questions) {
          if (reply?.data?.error == 'Insufficient Questions') {
            setHasInsufficientQuestions(true)
          }
          // setSections(reply.data.questions)
          // setAllSections(reply.data.questions.map((section: any) => section._id))
          setSearch({ searchItems: [...search?.searchItems, ...reply.data.questions], selectedItems: [] });
          DisplayLoader(false, '')
        } else {
          setErrors('Unfortunately we were unable to generate for this configuration')
          //TODO
        }
      }, (error) => {
        //reject(error)//TODO
        setErrors('Something went wrong')
        DisplayLoader(false, '')
      });
  }
  const addSelectedSections = () => {
    let values: any = {};
    values.challengeId = window.location.pathname.split("/")[2];
    values.sectionPosition = currentSectionNo;
    values.sections = search.selectedItems;
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/add-selected-sections/', values).then((reply) => {
      if (reply?.data) {
        updateChallenge(currentSectionNo);
        setSectionState(0);
      } else {
        DisplayLoader(false, '')
        //TODO
      }
    }, (error) => {
      //reject(error)//TODO
      DisplayLoader(false, '')
    });
  }
  const replaceSelectedSections = (section: any) => {
    let values: any = {};
    values.challengeId = window.location.pathname.split("/")[2];
    values.sectionPosition = currentSectionNo;
    values.section = section;
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/replace-selected-section/', values).then((reply) => {
      if (reply?.data) {
        setShowReplaceModal(false)
        updateChallenge(currentSectionNo);
        setSectionState(0);
      } else {
        DisplayLoader(false, '')
        //TODO
      }
    }, (error) => {
      //reject(error)//TODO
      DisplayLoader(false, '')
    });
  }
  return (
    <div className='rounded border border-dark p-2 pb-0 shadow-lg bg-light shadow'>
      {challenge && !challenge.heading && challenge._id &&
        <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
          validationSchema={challengeValidationSchema} initialValues={challengeInitValues} onSubmit={saveChallenge}>
          {({ errors, touched, values, setFieldValue, setErrors }) => (
            <Form>
              <div className='bg-light rounded p-2 col-12 d-inline-block'>
                <div><label className='form-label fw-bolder text-dark fs-6'>Module Name</label></div>
                <div>
                  <Field type='text' className='form-control form-control-lg mb-1'
                    name='heading' placeholder='Module Name' />
                </div>
                {ErrorDisplay('heading', errors)}
                <label className='ms-1 form-label fw-bolder text-dark fs-6 mt-3'>Mode of Presentation</label>
                <Field as="select" name="presentationMode" className="form-select mb-1" onChange={(e: any) => { setFieldValue('presentationMode', e.target.value) }}>
                  <option value={''}><b>-Select-</b></option>
                  <option value={'online'}>With Questions</option>
                  <option value={'offline'}>W/o Questions (Offline Tests)</option>
                </Field>
                {ErrorDisplay('presentationMode', errors)}
                <label className='ms-1 form-label fw-bolder text-dark fs-6 mt-3'>Content Type</label>
                <Field as="select" name="type" className="form-select mb-1" onChange={(e: any) => { setFieldValue('type', e.target.value) }}>
                  <option value={''}><b>-Select-</b></option>
                  {values.presentationMode == 'online' && <option value={'lesson'}>Lesson</option>}
                  {values.presentationMode == 'online' && <option value={'assignment'}>Assignment</option>}
                  <option value={'test'}>Test</option>
                </Field>
                {ErrorDisplay('type', errors)}
                <div className='mt-2'>
                  <button className='btn btn-primary float-end'>Create</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      }
      {challenge && challenge.heading &&
        <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
          validationSchema={validationSchema} initialValues={initValues} onSubmit={saveSection}>
          {({ errors, touched, values, setFieldValue, setErrors }) => (
            <Form>
              <div>
                <div className='bg-light rounded p-2 col-12 d-inline-block'>
                  <div>
                    <label className='form-label fw-bolder text-dark fs-6'>Module Name</label>
                    <a href={'/create-sections/' + id} className='btn btn-info p-2 ms-3'>Add Sections</a>
                    <ReportsList id={id ? id : ''} />
                    <Present id={id ? id : ''} challenge={challenge} sections={sections} presentStateIn={parseInt(presentState || '0')} />
                    <button onClick={() => { navigate('/print-test', { state: { sections: challenge.sections?.map((section: string) => sections[section]), title: challenge?.heading } }) }} type='button' className='btn btn-info m-1 float-end p-2 pt-1 pb-1 shadow-lg'>
                      <i className="fa fa-tv"></i><small>Print</small>
                    </button>
                    <button type='button' onClick={() => { setShowMarkingModal(true) }} className='btn btn-info m-1 float-end p-2 pt-1 pb-1 shadow-lg'>
                      <i className="fa fa-check-square"></i><small>Marks</small>
                    </button>
                  </div>
                  <div className='mt-1'>
                    <div className='col-11 d-inline-block'>
                      <Field type='text' className='form-control form-control-lg mb-1'
                        name='heading' placeholder='Challenge Name' disabled={!headingEdit} />
                    </div>
                    <div className='col-1 d-inline-block'>
                      {!headingEdit && <button type='button' className='btn btn-sm btn-default' onClick={() => { setHeadingEdit(true) }}><i className="fa fa-pencil"></i></button>}
                      {headingEdit && <button className='btn btn-sm btn-dark' onClick={() => { setHeadingEdit(false); saveSection(values) }}><i className="fa fa-save"></i></button>}
                      <button type='button' className='btn btn-info p-2 pt-1 pb-1' onClick={() => { setShowCopyModal(true) }}><i className="fa fa-copy"></i></button>
                    </div>
                  </div>
                </div>
                {ErrorDisplay('heading', errors)}
                <hr className='m-2' />
                {sectionState === 0 && <div className='col-12 d-inline-block bg-light p-1 align-top border border-left-0 border-top-0 border-bottom-0 border-dark'>
                  {
                    challenge && challenge.sections && challenge.sections?.map((section: string, index: number) => {
                      if (sections[section]) {
                        return (
                          <div key={index}>
                            <div className={'shadow bg-light mb-1 rounded p-1 border ' + (index === currentSectionNo ? 'border-success border-3' : 'border-dark border-1')} style={{ width: "100%", textAlign: "left" }}>
                              {<button type='button' className='btn btn-dark p-2' onClick={() => { moveSection('up', index) }} style={{ position: "absolute", left: "50px", marginTop: "-5px" }}><i className="fa fa-caret-up"></i></button>}
                              {<button type='button' className='btn btn-dark p-2' onClick={() => { moveSection('down', index) }} style={{ position: "absolute", left: "100px", marginTop: "-5px" }}><i className="fa fa-caret-down"></i></button>}
                              {<button type='button' className='btn btn-dark p-2' onClick={() => { setCurrentSectionNo(index); selectCustomModalByUrl(); setSearch({ ...search, ...{ selectedItems: [], searchItems: [] } }) }} style={{ position: "absolute", right: "185px", marginTop: "-5px" }}>{!challenge.presentationMode ? 'Add Section' : challengeTypeToAddSectionNameMap[challenge.presentationMode][challenge.type]}</button>}
                              {/* {<button type='button' onClick={() => { setCurrentSectionNo(index); setShowReplaceModal(true); setSearch({ ...search, ...{ searchItems: [] } }) }} className='btn btn-dark p-2' style={{ position: 'absolute', right: "318px", marginTop: "-5px" }}>Replace</button>} */}
                              {<button type='button' onClick={() => { setCurrentSectionNo(index); setSectionState(-1); setTimeout(() => { setSectionState(2) }, 10) }} className='btn btn-dark p-2' style={{ position: 'absolute', right: "50px", marginTop: "-5px" }}>Edit Section</button>}
                              <div className='text-center text-dark'><b>Section {index + 1}</b></div>
                              <div >
                                <div className='mt-4'>
                                  {
                                    sections[section]?.tags && sections[section]?.tags?.indexOf('competency') != -1 && <span className='p-2 bg-dark text-light rounded mb-1'>Competency</span>
                                  }
                                </div>
                                {ViewSection(sections[section], true, 1, errors, sections[section], setFieldValue, ((sections[section].type == 'pair' || sections[section].type == 'imageselect' || sections[section].type == 'crossword') ? { ...sections[section], ...{ correct: true } } : ((sections[section].type == 'blanks' || sections[section].type == 'blanks-any' || sections[section].type == 'mindmapquestion' || sections[section].type == 'dragdrop' || sections[section].type == 'verticalsequence' || sections[section].type == 'horizontalsequence' || sections[section].type == '7lives' || sections[section].type == 'short') ? { ...sections[section], ...{ correct: false } } : sections[section])))}
                              </div>
                            </div>
                            {/*<button type='button' onClick={() => { setCurrentSectionNo(index); setSectionState(1) }} className='btn btn-dark p-2' style={{ position: 'absolute', marginLeft: "-100px", marginTop: "-0px" }}>Preview</button>*/}
                          </div>
                        )
                      } else {
                        return <></>
                      }
                    })
                  }
                  <div className='text-center'><button type='button' onClick={() => { setCurrentSectionNo(challenge?.sections ? challenge.sections.length : 0); selectCustomModalByUrl(); setSearch({ ...search, ...{ selectedItems: [], searchItems: [] } }) }} className='btn btn-dark p-4' >{!challenge.presentationMode ? 'Add Section' : challengeTypeToAddSectionNameMap[challenge.presentationMode][challenge.type]}</button></div>
                </div>}
                {sectionState === 1 && <div className='col-12 d-inline-block bg-light p-1 align-top border border-left-0 border-top-0 border-bottom-0 border-dark'>
                  <button className='btn btn-dark' onClick={() => { setSectionState(0) }}>Back</button>
                  <div className='text-center'><b>Section {currentSectionNo + 1}</b></div>
                  {
                    ViewSection(sections[challenge.sections[currentSectionNo]], true, 1, errors, values, setFieldValue)
                  }
                </div>}
                {id && sectionState === 2 && <div className='col-12 d-inline-block shadow bg-light rounded p-5 card-body'>
                  <>
                    <div className='text-center text-dark'><b>Section {currentSectionNo + 1}</b></div>
                    {EditSection(values, errors, setFieldValue, setValidationSchema)}
                    <div className='mt-10'>
                      <button className='btn btn-dark float-end'>Save</button>
                      <button type="button" className="btn btn-danger float-start" onClick={() => { setShowDeleteModal(true) }}>Delete Section</button>

                    </div>
                  </>
                  {false && id && sectionState === 0 &&
                    <>
                      <div>
                        <label className='form-label fw-bolder text-dark fs-6'>Points:</label><h6 className='d-inline' style={{ marginLeft: "5px" }}>{sections[challenge.sections[currentSectionNo]]?.points}</h6>
                      </div>
                      {ViewSection(sections[challenge.sections[currentSectionNo]], true, 1, errors, values, setFieldValue)}
                      <div className='m-5'>
                        <button type='button' onClick={() => { setSectionState(-1); setTimeout(() => { setSectionState(2) }, 10) }} className='btn btn-dark float-end'>Edit</button>
                      </div>
                    </>}
                </div>}

              </div>
            </Form>
          )}
        </Formik>
      }
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showCopyModal} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowCopyModal(false) }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="deleteModalLabel">Copy Module</h5>
            <button type="button" className="btn-close" onClick={() => { setShowCopyModal(false) }} aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <h5>Are you sure that you want to copy this module?</h5>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => { setShowCopyModal(false) }}>Close</button>
            <button type="button" className="btn btn-primary" onClick={() => { copyModule(); setShowCopyModal(false) }}>Copy</button>
          </div>
        </div>
      </Modal>
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showMarkingModal} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowMarkingModal(false) }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="deleteModalLabel">Marking Scheme</h5>
            <button type="button" className="btn-close" onClick={() => { setShowMarkingModal(false) }} aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div>
              Marking Scheme :
              <Select value={[challenge?.extraDetails?.markingScheme].map((topic: any) => ({ value: topic, label: topic }))} options={['', 'main-75'].map((topic: any) => { return { value: topic, label: topic } })} onChange={(v: any) => { let newChallenge = { ...challenge }; newChallenge.extraDetails = { 'markingScheme': v }; setChallenge(newChallenge) }}></Select>
            </div>
            <div className='row mb-1'>
              <div className='col-4'><b>Positive</b></div>
              <div className='col-4'><b>Negative</b></div>
              <div className='col-4'></div>
            </div>
            {
              challenge?.sections?.map((section: any, index: number) => {
                return <div className='row mb-1'>
                  <div className='col-4'>
                    <input type='number' value={challenge.markingScheme?.[section]?.[0]} onChange={(e) => { updateMarkingScheme(section, e.target.value, 'positive') }}></input>
                  </div>
                  <div className='col-4'>
                    <input type='number' value={challenge.markingScheme?.[section]?.[1]} onChange={(e) => { updateMarkingScheme(section, e.target.value, 'negative') }}></input>
                  </div>
                  <div className='col-4'>
                    <button className='btn btn-dark p-2' onClick={(e) => { copyMarkingScheme(index) }}>Copy Below</button>
                  </div>
                </div>
              })
            }
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => { setShowMarkingModal(false) }}>Close</button>
            <button type="button" className="btn btn-dark" onClick={() => { saveMarkingScheme(); setShowMarkingModal(false) }}>Save</button>
          </div>
        </div>
      </Modal>
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showDeleteModal} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowDeleteModal(false) }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="deleteModalLabel">Delete Section</h5>
            <button type="button" className="btn-close" onClick={() => { setShowDeleteModal(false) }} aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <h5>Are you sure that you want to delete this section?</h5>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => { setShowDeleteModal(false) }}>Close</button>
            <button type="button" className="btn btn-danger" onClick={() => { deleteSection(currentSectionNo); setShowDeleteModal(false) }}>Delete</button>
          </div>
        </div>
      </Modal>
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showAddModal} dialogClassName='modal-xl modal-dialog modal-fullscreen' aria-hidden='true' onHide={() => { setShowAddModal(false) }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="deleteModalLabel">Add Question</h5>
            <button type="button" className="btn-close" onClick={() => { setShowAddModal(false) }} aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {errors && <div className='bg-danger text-white mb-3 rounded p-5 text-center'>{'*' + errors}</div>}
            <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
              initialValues={{ topics: [], noOfQuestions: 10, questionType: { value: 'mcq', label: 'MCQ' }, difficultyLevel: { value: 'any', label: 'Any' } }} onSubmit={searchQuestionsByTag}>
              {({ errors, touched, values, setFieldValue, setErrors }) => (
                <Form>
                  <div className='p-5 pb-0'>
                    <table className='table table-bordered text-center'>
                      <thead>
                        <tr>
                          <th className='col-4'>Topic</th>
                          <th className='col-4'>Sub Topic</th>
                          <th className='col-2'>Question Type</th>
                          <th className='col-2'>Difficulty</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subjectQuestionConfig.map((config: any, index: number) => {
                          return <tr>
                            <td className='col-4'>
                              <Select value={config.topics} onChange={(v: any) => { let newSubjectQuestionConfig = [...subjectQuestionConfig]; newSubjectQuestionConfig[index].topics = [v]; newSubjectQuestionConfig[index].subtopics = []; setSubjectQuestionConfig(newSubjectQuestionConfig) }}
                                options={[...topicListForStream['JEE-NEET']['Physics'], ...topicListForStream['JEE-NEET']['Chemistry'], ...topicListForStream['JEE-NEET']['Mathematics'], ...topicListForStream['JEE-NEET']['Botany'], ...topicListForStream['JEE-NEET']['Zoology']].map((topic: any) => { return { value: topic.toLocaleLowerCase(), label: topic } })} />
                            </td>
                            <td className='col-4'>
                              <Select value={config.subtopics} onChange={(v: any) => { let newSubjectQuestionConfig = [...subjectQuestionConfig]; newSubjectQuestionConfig[index].subtopics = v.map((v1: any) => v1); setSubjectQuestionConfig(newSubjectQuestionConfig) }}
                                isMulti options={subTopicList[subjectQuestionConfig[index]?.topics?.[0]?.label]?.map((topic: any) => { return { value: topic.toLocaleLowerCase(), label: topic } })} />
                            </td>
                            <td className='col-2'>
                              <Select value={config.questionType} onChange={(v: any) => { let newSubjectQuestionConfig = [...subjectQuestionConfig]; newSubjectQuestionConfig[index].questionType = v; setSubjectQuestionConfig(newSubjectQuestionConfig) }}
                                options={[{ value: 'mcq', label: 'MCQ' }, { value: 'numerical', label: 'Numerical' }]} />
                            </td>
                            <td className='col-2'>
                              <Select value={config.difficultyLevel} onChange={(v: any) => { let newSubjectQuestionConfig = [...subjectQuestionConfig]; newSubjectQuestionConfig[index].difficultyLevel = v; setSubjectQuestionConfig(newSubjectQuestionConfig) }}
                                options={[{ value: 'any', label: 'Any' }, { value: 'easy', label: 'Easy' }, { value: 'medium', label: 'Medium' }, { value: 'hard', label: 'Hard' }]} />
                            </td>
                          </tr>
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className='text-center'>
                    <button className='btn btn-dark'>Get Questions</button>
                  </div>
                </Form>)}
            </Formik>
            <hr />
            {search?.searchItems?.length > 0 && <h4 className='text-center'>Questions</h4>}
            <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
              validationSchema={validationSchema} initialValues={initValues} onSubmit={saveSection}>
              {({ errors, touched, values, setFieldValue, setErrors }) => (
                <Form>
                  {search?.searchItems?.map((item: any, index: number) => {
                    if (challenge.sections.indexOf(item._id) !== -1) return <></>;
                    return <div className='p-2'>
                      {search.selectedItems.indexOf(item) === -1 && <button type='button' onClick={() => { setSearch({ ...search, ...{ selectedItems: [...search.selectedItems, item] } }) }} className='btn btn-dark p-2' style={{ position: 'absolute', right: "25px", zIndex: 1000 }}>Select</button>}
                      {search.selectedItems.indexOf(item) !== -1 && <button type='button' onClick={() => { search.selectedItems.splice(index, 1); setSearch({ ...search, ...{ selectedItems: search.selectedItems } }) }} className='btn btn-success p-2' style={{ position: 'absolute', right: "25px", zIndex: 1000 }}>Selected</button>}
                      {ViewSection(item, true, 1, errors, values, setFieldValue, ((item.type == 'pair' || item.type == 'imageselect' || item.type == 'crossword') ? { ...item, ...{ correct: true } } : ((item.type == 'blanks' || item.type == 'blanks-any' || item.type == 'mindmapquestion' || item.type == 'dragdrop' || item.type == 'verticalsequence' || item.type == 'horizontalsequence' || item.type == '7lives' || item.type == 'short') ? { ...item, ...{ correct: false } } : item)))}
                      <hr />
                    </div>
                  })}
                </Form>)}
            </Formik>
            {search.searchItems.length > 0 && <div>
              <button className='btn btn-dark' onClick={() => { searchQuestionsByTag() }}>More Questions</button>
            </div>}
          </div>
          <div className="modal-footer pt-2 pb-2 d-flex justify-content-end">
            <div>
              <button type="button" className="btn btn-secondary" onClick={() => { setShowAddModal(false) }}>Close</button>
              {search.selectedItems.length > 0 && <button type="button" className="btn btn-success ms-3" onClick={() => { addSelectedSections(); setShowAddModal(false) }}>{!challenge.presentationMode ? 'Add Section' : challengeTypeToAddSectionNameMap[challenge.presentationMode][challenge.type]}</button>}
            </div>
          </div>
        </div>
      </Modal>
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showSelectModal} dialogClassName='modal-xl modal-dialog modal-fullscreen' aria-hidden='true' onHide={() => { setShowSelectModal(false) }}>
        <div className="modal-content">
          <div className="modal-header pt-2 pb-0">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="query">Search</span>
              </div>
              <div className='form-control p-0' style={{ border: '0px' }}>
                <input id="searchText" type="text" onChange={(e) => { searchQuestions(0) }} className="form-control" placeholder="Your query...." aria-label="Query" aria-describedby="query" />
                <div id='searchWarning' style={{ fontSize: "10px" }}>Type atleast 3 letters to start search</div>
              </div>
            </div>
            <button type="button" id="addModalClose" className="btn-close mb-7" onClick={() => { setShowSelectModal(false) }} aria-label="Close"></button>
          </div>
          <div id="searchList" className="modal-body pt-2 pb-2">
            <div>
              <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
                validationSchema={validationSchema} initialValues={initValues} onSubmit={saveSection}>
                {({ errors, touched, values, setFieldValue, setErrors }) => (
                  <Form>
                    {challenge && challenge.heading && <div className="dropdown">
                      <button className="btn btn-primary dropdown-toggle p-2" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                        Filter Section Types
                      </button>
                      <ul className="dropdown-menu pe-2" aria-labelledby="dropdownMenuButton">
                        {
                          challenge.presentationMode && challengeTypeToQuestionTypeMap[challenge.presentationMode][challenge.type].map((sectionType: string) => {
                            if (sectionType === 'slide') return <li className='mb-1'>
                              <input onChange={() => { updateChallengeFilter('slide'); searchQuestions(0); }} className="form-check-input" type="checkbox" value="slide" id="checkbox1" name="fillterqtype" checked={challengeFilter['slide']} />
                              <label className="form-check-label ms-1" >Slide</label>
                            </li>
                            if (sectionType === 'simulation') return <li className='mb-1'>
                              <input onChange={() => { updateChallengeFilter('simulation'); searchQuestions(0); }} className="form-check-input" type="checkbox" value="simulation" id="checkbox1" name="fillterqtype" checked={challengeFilter['simulation']} />
                              <label className="form-check-label ms-1" >Simulation</label>
                            </li>
                            if (sectionType === 'simulationgraph') return <li className='mb-1'>
                              <input onChange={() => { updateChallengeFilter('simulationgraph'); searchQuestions(0) }} className="form-check-input" type="checkbox" value="simulationgraph" id="checkbox3" name="fillterqtype" checked={challengeFilter['simulationgraph']} />
                              <label className="form-check-label ms-1" >Simulation (graph)</label>
                            </li>
                            if (sectionType === 'mcq') return <li className='mb-1'>
                              <input onChange={() => { updateChallengeFilter('mcq'); searchQuestions(0) }} className="form-check-input" type="checkbox" value="mcq" id="checkbox2" name="fillterqtype" checked={challengeFilter['mcq']} />
                              <label className="form-check-label ms-1" >Multiple Choice Question (MCQ)</label>
                            </li>
                            if (sectionType === 'multiple') return <li className='mb-1'>
                              <input onChange={() => { updateChallengeFilter('multiple'); searchQuestions(0) }} className="form-check-input" type="checkbox" value="multiple" id="checkbox3" name="fillterqtype" checked={challengeFilter['multiple']} />
                              <label className="form-check-label ms-1" >Multiple Choice Question with Multiple Answers</label>
                            </li>
                            if (sectionType === 'partialmultiple') return <li className='mb-1'>
                              <input onChange={() => { updateChallengeFilter('partialmultiple'); searchQuestions(0) }} className="form-check-input" type="checkbox" value="multiple" id="checkbox3" name="fillterqtype" checked={challengeFilter['partialmultiple']} />
                              <label className="form-check-label ms-1" >Multiple Choice Question with Multiple Answers(Partial Marking)</label>
                            </li>
                            if (sectionType === 'numerical') return <li className='mb-1'>
                              <input onChange={() => { updateChallengeFilter('numerical'); searchQuestions(0) }} className="form-check-input" type="checkbox" value="numerical" id="checkbox3" name="fillterqtype" checked={challengeFilter['numerical']} />
                              <label className="form-check-label ms-1" >Numerical</label>
                            </li>
                            if (sectionType === 'pair') return <li className='mb-1'>
                              <input onChange={() => { updateChallengeFilter('pair'); searchQuestions(0) }} className="form-check-input" type="checkbox" value="pair" id="checkbox3" name="fillterqtype" checked={challengeFilter['pair']} />
                              <label className="form-check-label ms-1" >Pair Match</label>
                            </li>
                            if (sectionType === 'short') return <li className='mb-1'>
                              <input onChange={() => { updateChallengeFilter('short'); searchQuestions(0) }} className="form-check-input" type="checkbox" value="short" id="checkbox3" name="fillterqtype" checked={challengeFilter['short']} />
                              <label className="form-check-label ms-1" >Short Text</label>
                            </li>
                            if (sectionType === 'long') return <li className='mb-1'>
                              <input onChange={() => { updateChallengeFilter('long'); searchQuestions(0) }} className="form-check-input" type="checkbox" value="long" id="checkbox3" name="fillterqtype" checked={challengeFilter['long']} />
                              <label className="form-check-label ms-1" >Long Text</label>
                            </li>
                            if (sectionType === 'blanks') return <li className='mb-1'>
                              <input onChange={() => { updateChallengeFilter('blanks'); searchQuestions(0) }} className="form-check-input" type="checkbox" value="blanks" id="checkbox3" name="fillterqtype" checked={challengeFilter['blanks']} />
                              <label className="form-check-label ms-1" >Fill in the blanks</label>
                            </li>
                            if (sectionType === 'matrix') return <li className='mb-1'>
                              <input onChange={() => { updateChallengeFilter('matrix'); searchQuestions(0) }} className="form-check-input" type="checkbox" value="matrix" id="checkbox3" name="fillterqtype" checked={challengeFilter['matrix']} />
                              <label className="form-check-label ms-1" >Matrix Match</label>
                            </li>
                            if (sectionType === 'imageselect') return <li className='mb-1'>
                              <input onChange={() => { updateChallengeFilter('imageselect'); searchQuestions(0) }} className="form-check-input" type="checkbox" value="imageselect" id="checkbox3" name="fillterqtype" checked={challengeFilter['imageselect']} />
                              <label className="form-check-label ms-1" >Image Select</label>
                            </li>
                            if (sectionType === 'imagedraw') return <li className='mb-1'>
                              <input onChange={() => { updateChallengeFilter('imagedraw'); searchQuestions(0) }} className="form-check-input" type="checkbox" value="imagedraw" id="checkbox3" name="fillterqtype" checked={challengeFilter['imagedraw']} />
                              <label className="form-check-label ms-1" >Image Draw</label>
                            </li>
                            if (sectionType === 'click') return <li className='mb-1'>
                              <input onChange={() => { updateChallengeFilter('click'); searchQuestions(0) }} className="form-check-input" type="checkbox" value="click" id="checkbox3" name="fillterqtype" checked={challengeFilter['click']} />
                              <label className="form-check-label ms-1" >Click</label>
                            </li>
                            if (sectionType === 'crossword') return <li className='mb-1'>
                              <input onChange={() => { updateChallengeFilter('crossword'); searchQuestions(0) }} className="form-check-input" type="checkbox" value="crossword" id="checkbox3" name="fillterqtype" checked={challengeFilter['crossword']} />
                              <label className="form-check-label ms-1" >Crossword</label>
                            </li>
                            if (sectionType === '7lives') return <li className='mb-1'>
                              <input onChange={() => { updateChallengeFilter('7lives'); searchQuestions(0) }} className="form-check-input" type="checkbox" value="7lives" id="checkbox3" name="fillterqtype" checked={challengeFilter['7lives']} />
                              <label className="form-check-label ms-1" >7 Lives Game</label>
                            </li>
                            if (sectionType === 'mindmapslide') return <li className='mb-1'>
                              <input onChange={() => { updateChallengeFilter('mindmapslide'); searchQuestions(0) }} className="form-check-input" type="checkbox" value="mindmapslide" id="checkbox3" name="fillterqtype" checked={challengeFilter['mindmapslide']} />
                              <label className="form-check-label ms-1" >Mind Map Question</label>
                            </li>
                            if (sectionType === 'mindmapquestion') return <li className='mb-1'>
                              <input onChange={() => { updateChallengeFilter('mindmapquestion'); searchQuestions(0) }} className="form-check-input" type="checkbox" value="mindmapquestion" id="checkbox3" name="fillterqtype" checked={challengeFilter['mindmapquestion']} />
                              <label className="form-check-label ms-1" >Mind Map Question</label>
                            </li>
                            if (sectionType === 'mindmapactivity') return <li className='mb-1'>
                              <input onChange={() => { updateChallengeFilter('mindmapactivity'); searchQuestions(0) }} className="form-check-input" type="checkbox" value="mindmapactivity" id="checkbox3" name="fillterqtype" checked={challengeFilter['mindmapactivity']} />
                              <label className="form-check-label ms-1" >Mindmap Activity</label>
                            </li>
                            if (sectionType === 'dragdrop') return <li className='mb-1'>
                              <input onChange={() => { updateChallengeFilter('dragdrop'); searchQuestions(0) }} className="form-check-input" type="checkbox" value="dragdrop" id="checkbox3" name="fillterqtype" checked={challengeFilter['dragdrop']} />
                              <label className="form-check-label ms-1" >Drag and Drop</label>
                            </li>
                            if (sectionType === 'verticalsequence') return <li className='mb-1'>
                              <input onChange={() => { updateChallengeFilter('verticalsequence'); searchQuestions(0) }} className="form-check-input" type="checkbox" value="verticalsequence" id="checkbox3" name="fillterqtype" checked={challengeFilter['verticalsequence']} />
                              <label className="form-check-label ms-1" >Sequence (Vertical)</label>
                            </li>
                            if (sectionType === 'horizontalsequence') return <li className='mb-1'>
                              <input onChange={() => { updateChallengeFilter('horizontalsequence'); searchQuestions(0) }} className="form-check-input" type="checkbox" value="horizontalsequence" id="checkbox3" name="fillterqtype" checked={challengeFilter['horizontalsequence']} />
                              <label className="form-check-label ms-1" >Sequence (Horizontal)</label>
                            </li>
                          })
                        }
                        {
                          !challenge.presentationMode && <>
                            <li className='mb-1'>
                              <input onChange={() => { updateChallengeFilter('simulation'); searchQuestions(0) }} className="form-check-input" type="checkbox" value="simulation" id="checkbox3" name="fillterqtype" checked={challengeFilter['simulation']} />
                              <label className="form-check-label ms-1" >Simulation</label>
                            </li>
                          </>
                        }
                      </ul>
                    </div>}
                    {search?.searchItems?.map((item: any, index: number) => {
                      if (challenge.sections.indexOf(item._id) !== -1) return <></>;
                      return <div className='p-2 rounded border border-dark'>
                        {search.selectedItems.indexOf(item) === -1 && <button type='button' onClick={() => { setSearch({ ...search, ...{ selectedItems: [...search.selectedItems, item] } }) }} className='btn btn-dark p-2' style={{ position: 'absolute', right: "25px", zIndex: 1000 }}>Select</button>}
                        {search.selectedItems.indexOf(item) !== -1 && <button type='button' onClick={() => { search.selectedItems.splice(index, 1); setSearch({ ...search, ...{ selectedItems: search.selectedItems } }) }} className='btn btn-success p-2' style={{ position: 'absolute', right: "25px", zIndex: 1000 }}>Selected</button>}
                        {ViewSection(item, true, 1, errors, values, setFieldValue)}
                      </div>
                    })}
                    <div>
                      {search.more && <button type='button' onClick={() => { searchQuestions(search.skipCount + 10) }} className='btn btn-dark'>More</button>}
                    </div>
                  </Form>)}
              </Formik>
            </div>
          </div>
          <div className="modal-footer pt-2 pb-2 d-flex justify-content-between">
            <button type='button' className='btn btn-dark m-3' onClick={() => { addSection(currentSectionNo); setShowSelectModal(false) }}>Create your own section</button>
            <div>
              <button type="button" className="btn btn-secondary" onClick={() => { setShowSelectModal(false) }}>Close</button>
              {search.selectedItems.length > 0 && <button type="button" className="btn btn-success ms-3" onClick={() => { addSelectedSections(); setShowSelectModal(false) }}>{!challenge.presentationMode ? 'Add Section' : challengeTypeToAddSectionNameMap[challenge.presentationMode][challenge.type]}</button>}
            </div>
          </div>
        </div>
      </Modal>
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showReplaceModal} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowReplaceModal(false) }}>
        <div className="modal-content">
          <div className="modal-header">

            <div className='form-control p-0' style={{ border: '0px' }}>
              <Select onChange={(v) => setReplacementTopics(v.map((v1: any) => v1.value))} id="replaceSections" isMulti
                options={[...topicListForStream['JEE-NEET']['Physics'], ...topicListForStream['JEE-NEET']['Chemistry'], ...(sections?.[challenge?.sections?.[currentSectionNo]]?.type == 'mains' ? topicListForStream['JEE-NEET']['Mathematics'] : [...topicListForStream['JEE-NEET']['Botany'], ...topicListForStream['JEE-NEET']['Zoology']])].map((topic: any) => { return { value: topic.toLocaleLowerCase(), label: topic } })} />
            </div>
            <div className="input-group-append">
              <button className="input-group-text" id="query" onClick={() => { replaceSection() }}>Search</button>
            </div>
          </div>
          <div className="modal-body pt-2 pb-2">
            <div>
              <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
                validationSchema={validationSchema} initialValues={initValues} onSubmit={saveSection}>
                {({ errors, touched, values, setFieldValue, setErrors }) => (
                  <Form>
                    {search?.searchItems?.map((item: any, index: number) => {
                      if (challenge.sections.indexOf(item._id) !== -1) return <></>;
                      return <div className='p-2 rounded border border-dark'>
                        {/* {search.selectedItems.indexOf(item) === -1 && <button type='button' onClick={() => { setSearch({ ...search, ...{ selectedItems: [...search.selectedItems, item] } }) }} className='btn btn-dark p-2' style={{ position: 'absolute', right: "25px", zIndex: 1000 }}>Select</button>}
                        {search.selectedItems.indexOf(item) !== -1 && <button type='button' onClick={() => { search.selectedItems.splice(index, 1); setSearch({ ...search, ...{ selectedItems: search.selectedItems } }) }} className='btn btn-success p-2' style={{ position: 'absolute', right: "25px", zIndex: 1000 }}>Selected</button>} */}
                        <button type='button' onClick={() => { replaceSelectedSections(item) }} className='btn btn-success p-2' style={{ position: 'absolute', right: "25px", zIndex: 1000 }}>Use This</button>
                        {ViewSection(item, true, 1, errors, values, setFieldValue, ((item.type == 'pair' || item.type == 'imageselect' || item.type == 'crossword') ? { ...item, ...{ correct: true } } : ((item.type == 'blanks' || item.type == 'blanks-any' || item.type == 'mindmapquestion' || item.type == 'dragdrop' || item.type == 'verticalsequence' || item.type == 'horizontalsequence' || item.type == '7lives' || item.type == 'short') ? { ...item, ...{ correct: false } } : item)))}
                      </div>
                    })}
                    {/* <div>
                      {search.more && <button type='button' onClick={() => { searchQuestions(search.skipCount + 10) }} className='btn btn-dark'>More</button>}
                    </div> */}
                  </Form>)}
              </Formik>
              {search?.searchItems?.length > 0 && <button type='button' onClick={() => { replaceSection(true) }} className='mt-2 mb-4 btn btn-dark float-end'>More</button>}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export { CreateModule }
