import { FC, useState } from 'react'
import * as Yup from 'yup'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { Field, Form, Formik, FormikValues } from 'formik'
import { DisplayLoader } from '../../utils/loader'
import axios from 'axios'
import { ErrorDisplay } from '../../modules/components/common/ErrorDisplay'


const GenerateOMR: FC = () => {

    return (
        <div className='text-center'>
            <h1>Generate OMR</h1>
            <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
                validationSchema={() => { }} initialValues={{ type: 'neet' }} onSubmit={() => { }}>
                {({ errors, touched, values, setFieldValue, setErrors }) => (
                    <Form >
                        <Field as="select" name="type">
                            <option value="neet">NEET</option>
                            {/* <option value="mains">JEE Mains</option> */}
                            <option value="custom">Custom</option>
                        </Field>
                        {values.type == 'custom' &&
                            <><div><label className='form-label fw-bolder text-dark fs-6'>No. Of Questions</label></div>
                                <Field type='text' className='form-control form-control-lg mb-1'
                                    name="noOfQuestions" maxLength={6} pattern="\d{6}" placeholder='No. Of Questions' />
                            </>
                        }
                        <button type='button' className='btn btn-primary m-2' onClick={() => { }}>Get OMR</button>
                        <button type='button' className='btn btn-primary float-end' onClick={() => { }}>Get Template</button>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export { GenerateOMR }
